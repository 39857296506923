.accordion__wrapper {
    width: 100%;
    max-width: $maxWidth;
    margin-right: auto;
    margin-left: auto;
}

.accordion {
    max-width: 40rem;
    position: relative;
    margin: 2rem $sidesMobile;
}

.accordion__item {
    padding: 1rem 1.5rem;
    background-color: $blue-50;
    box-shadow: 0 1px 2px 0 rgba(1,3,4,0.15), 0 10px 15px 0 rgba(1,3,4,0.05), inset 0 1px 0 0 #E9F0F6;
}

.accordion__item--first {
    border-radius: 3px 3px 0 0;
}

.accordion__item--last {
    border-radius: 0 0 3px 3px;
}

.accordion__item__header {
    cursor: pointer;
}

.accordion__item__icon {
    width: 0.6rem;
    height: auto;
    margin-right: 1rem;
    @include svg__filter__blue300;
}

.accordion__item__headline {
    @include font-stack(epsilon);
    margin: 0;
}

.accordion__item__text {
    opacity: 0;
    height: 0;
    padding: 0;
    margin: 0;
    overflow: hidden;
}

.accordion__item--open {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    margin-bottom: 1rem;
    margin-top: 1rem; 
    position: relative;

    &:after {
        content: '';
        position: absolute;

        top: 0;
        left: 1.5rem;
        height: 4px;
        width: 4rem;
        background-color: $yellow;
    }
}

@media only screen and (max-width: 600px) {
    .accordion {
        margin: 2rem $sidesMobile;
    }
}
