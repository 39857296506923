.career-intro__wrapper {
    display: flex;
    flex-direction: row;
}

.career__wrapper {
    background-color: $yellow;
    width: 50%;
    padding: 3rem $sidesMobile 3rem $sidesMobile;
}

.career {
  max-width: 500px;
  margin-left: auto;
  margin-right: 7rem;
  height: inherit;
}

.career__button {
    margin-top: 2rem;
    @include buttonWhite;
}

.career__header {
    @include font-stack(beta);
}

.career__content, .career__header {
    color: $blue-900 !important;
}

@media only screen and (max-width: 1400px) {
  .career {
    margin-right: auto;
  }

  .career .card-small {
    max-width: 100%;
  }
}

@media only screen and (max-width: 600px) {
    .career-intro__wrapper {
        flex-direction: column;
    }

    .career__wrapper {
        width: 100%;
        padding-top: 2rem;
        padding-bottom: 2rem;
    }

    .career__button {
      margin-top: 1rem;
    }
}

