.custom-heading{
  color: $blue-900;
  margin-bottom: 0;
}

.custom-heading__wrapper {
  max-width: $maxWidth;
  margin-left: auto;
  margin-right: auto;
  padding: 3rem $sidesMobile 0rem $sidesMobile;
}

@media only screen and (max-width: 600px) {
  .custom-heading__wrapper {
      padding-top: 2rem;
  }

  .custom-heading {
    margin-top: 0;
  }
}
