.contact-person__wrapper {
    background-color: $blue-50;
    width: 100vw;


}

.contact-person {
  max-width: $maxWidth;
  padding: 2rem $sidesMobile 0rem $sidesMobile;
  margin-left: auto;
  margin-right: auto;

}

#contact-person__flex-row {
    justify-content: flex-start;
    padding-bottom: 3rem;
    gap: 3rem;
}

.contact-person__header {
    @include font-stack(beta);
    color: $blue-500;
    margin-bottom: 2rem;
}

.contact-person__name {
    @include font-stack(gamma);
    color: $blue-500;
    margin-bottom: 0rem;
}

.contact-person__phone, .contact-person__email {
    @include buttonLightBlue;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.contact-person__icon {
    height: 1rem;
    padding-right: 1rem;
}

#contact-person__content > .flex-row {
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 1rem;
}

.contact-person__phone p, .contact-person__email p {
    margin: 0;
}

.contact-person__image-wrapper {
    position: relative;
    width: 11rem;
    min-width: 11rem;
    min-height: 11rem;
    height: 11rem;
    margin-left: -5px;

    background-color: $yellow;
    border-radius: 50%;

}

.contact-person__image {
    position: absolute;
    z-index: 3;
    transform: scale(1);
    border-radius: 50%;
    width: 10rem;
    height: 10rem;
    object-fit: cover;
  }

.contact-person__image__container {
    z-index: 4;
    top: 0.5rem;
    left: 0.5rem;
    border-radius: 50%;
    width: 10rem;
    height: 10rem;
    overflow: hidden;
    display: inline-block;
    position: relative;
    box-shadow: 0 1px 2px 0 rgba(1,3,4,0.3), 0 10px 15px 0 rgba(1,3,4,0.2), inset 0 1px 0 0 #F5F9FC;
}

.contact-person__mask__left {
    position: absolute;
    z-index: 2;

    width: 50%;
    height: 100%;
    background-color: $blue-50;
}

.contact-person__mask__right {
    position: absolute;
    z-index: 2;
    right: 0;

    width: 50%;
    height: 100%;
    background-color: $blue-50;
}

.contact-person__mask__bottom {
    position: absolute;
    z-index: 2;
    bottom: 0;

    width: 100%;
    height: 50%;
    background-color: $blue-50;
}

.contact-person__mask__top {
    position: absolute;
    z-index: 2;

    width: 100%;
    height: 50%;

    background-color: $blue-50;
}

@media only screen and (max-width: 768px) {
    .contact-person > .flex-row {
        flex-direction: column;
    }

    #contact-person__content {
        text-align: center;
    }

    #contact-person__flex-row {
        gap: 1.5rem;
    }

    #contact-person__content > .flex-row {
        align-items: center;
        justify-content: center;
    }
}
