.layout {
    display: flex;
}

.layout__row {
    flex-direction: row;
}

.flex-row {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: center;
}

.flex-row-plain {
	display: flex; 
	flex-direction: row;
}

.flex-row-sb {
	display: flex; 
	flex-direction: row;
	justify-content: space-between;
}

.flex-column-plain {
	display: flex; 
	flex-direction: column;
	position: relative;
}

.mb {
	margin-bottom: 1rem;
}


