.quote {
  background-color: $blue-50;
  width: 100%;
}

.quote__wrapper {
  margin-left: auto;
  margin-right: auto;
  max-width: $maxWidth;
  padding: 2rem $sidesMobile;
}

.quote__headline {
  @include font-stack(beta);
}

.quote__entry {
  gap: 1.5rem;
  position: relative;
}

.quote__card {
  width: 35rem;
  margin-bottom: 0.5rem;
  min-height: 8rem;
  overflow: visible;
  flex-shrink: 1;

  // Remove card click interatcions
  cursor: default;

  &:focus,
  &:hover {
      background: white;
  }

  &:active {
    &:after {
      width: 0;
      height: 0;
    }
  }
}

.quote__card__quotation__left, .quote__card__quotation__right {
  position: absolute;
  @include font-stack(alpha);
  color: $yellow;
  font-size: 7rem;
  z-index: 3;
  margin: 0;
}

.quote__card__quotation__left {
  bottom: 1.85rem;
  left: -1.5rem;
}

.quote__card__quotation__right {
  top: 2.25rem;
  right: -1.5rem;
}

.quote__picture {
  margin-top: -2rem;
}

.quotes {
  margin-top: 3rem;
  padding: 2rem 1.5rem;
}

.quote__content {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.quote__credit {
  padding-right: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.quote__credit > .contact-person__name {
  @include font-stack(epsilon);
  color: $yellow;
  line-height: 1rem;
  font-size: 1rem;
}

.quote__credit > .contact-person__position {
  @include font-stack(delta);
}

@media only screen and (max-width: 800px) {

  .quote__card {
    width: 100%;
  }

  .quote__entry {
    gap: 1.5rem;
  }
}

@media only screen and (max-width: 600px) {

  .quotes {
    margin-top: 0;
    padding-bottom: 0;
  }

  .quote__entry {
    margin-bottom: 7rem;
  }

  .quote__entry:last-child {
    margin-bottom: 4rem;
  }

  .quote__picture {
    position: absolute;
    width: 5.5rem;
    height: 5.5rem;
    min-width: 5.5rem;
    min-height: 5.5rem;
    z-index: 3;
    bottom: -4.5rem;
    right: 0rem;
  }

  .quote .contact-person__image__container {
    position: absolute;
    width: 5.5rem;
    height: 5.5rem;
    z-index: 3;
    bottom: -4.5rem;
    right: 0rem;
  }

  .quote__picture .contact-person__image {
    width: 5rem;
    height: 5rem;
    top: 0.25rem;
    left: 0.25rem;

  }

  .quote__card {
    max-width: none;
  }

}
