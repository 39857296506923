.header-wrapper {
    display: flex;
    flex-direction: column;
    background-color: transparent;
}

header {
    background-color: transparent;
    width: 100%;
    
}

