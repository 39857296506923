.open-positions {
    max-width: 500px;
    margin-right: auto;
    margin-left: 7rem;
    height: inherit;
}

.open-positions__wrapper {
    background-color: $yellow-500;
    height: inherit;
    width: 50%;
    padding: 3rem $sidesMobile 3rem $sidesMobile;
}

.open-positions__header {
    width: 50%;
    @include font-stack(beta);
    color: $blue-900 !important;
}

.open-positions__link:link, .open-positions__link:visited {
    color: $blue-500 !important;
    text-decoration: none;
    text-decoration: underline;
    text-underline-offset: 0.1em;
}

@media only screen and (max-width: 1400px) {
  .open-positions {
    margin-left: auto;
  }

  .open-positions .card-small {
    max-width: 100%;
  }
}

@media only screen and (max-width: 600px) {

    .open-positions__wrapper {
        width: 100%;
        padding-top: 2rem;
        padding-bottom: 2rem;
    }

}
