.about {
    display: flex;
    flex-direction: row-reverse;
    padding: 1rem $sidesMobile 2rem $sidesMobile;
    z-index: 2;
    max-width: calc( #{$maxWidth} );
    margin-left: auto;
    margin-right: auto;
}

.about__box {
    position: relative;
    background-color: $blue-50;
    width: 24rem;
    border-radius: 3px;
    padding-left: 2rem;
    padding-right: 3rem;
    padding-top: 2em;
    padding-bottom: 1rem;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.05), 0 1px 2px 0 rgba(0,3,5,0.20);

    &::after {
        content: '';
        position: absolute;
        background-color: $yellow;
        z-index: 5;
        top: calc(0.35rem / 2 * (-1));
        left: 2rem;
        width: 15rem;
        height: 0.35rem;
        position: absolute !important;
    }
}

.landing__backdrop {
  mask-image: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.95) 90%);
   -webkit-mask-image: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.95) 100%);

	top: 0;
	height: 38.5rem;
	width: 100%;
	position: absolute;
	z-index: -1;
  object-fit: cover;
}

@media only screen and (max-width: 450px) {

  .landing__backdrop {
    mask-image: unset;
     -webkit-mask-image: unset;

    top: 0;
    height: 38.5rem;
    width: 100%;
    position: absolute;
    z-index: 2;
    object-fit: cover;
  }

  .about {
    height: 45rem;
    background-color: $yellow;
    z-index: -2;
  }

  .about__box {
    z-index: 3;
    margin-top: 90%;
    height: fit-content;
  }
}

@media only screen and (max-width: 390px) {
  .about__box {
    &::after {
      width: 30vw;
    }
  }
}

.about__box__header {
    @include font-stack(beta);
    line-height: 2rem;
}

.about__box__button {
    margin-top: 1rem;
    @include buttonWhite;
}

.about__box__content {
    @include font-stack(base);
}
