.timeline {
  margin-left: auto;
  margin-right: auto;
  max-width: $maxWidth;
  height: 100%;
  padding: 4rem $sidesMobile;
  position: relative;
}

.timeline__headline {
  margin-bottom: 3rem;
}

.timeline__entries {
  padding: 1rem 0 1rem 1rem;
}

.timeline .card-small-plain {
  flex-direction: column;
  height: fit-content;
  margin-top: -0.25rem;
  width: 100%;
  &:after {
    content: '';
    position: absolute;

    top: 0rem;
    left: 1.5rem;

    height: 4px;
    width: 8rem;

    background-color: $yellow;
  }
}

.timeline__item {
  height: 100%;
  position: relative;
  gap: 1rem;

}

.timeline__item__date {
  margin-top: -0.3rem;
  width: 100%;
  max-width: 5rem;

}

.timeline__item__date__year {
  margin: 0;
  line-height: 1.5rem;
}

.timeline__item__date__month {
  @include font-stack(gamma);
  color: $yellow;
  margin: 0;
}

.timeline__item__structure {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 5rem;
  border-top: 5px solid $blue-50;
  border-left: 5px solid $blue-50;
  margin-top: 0.25rem;
  margin-bottom: -0.25rem;
}

.timeline__item__structure__sphere-top-left {
  background-color: $yellow;
  z-index: 2;
  position: absolute;

  width: 1.5rem;
  height: 1.5rem;
  left: -0.855rem;
  top: -0.85rem;
  border-radius: 50%;
}

.timeline__item__structure__sphere-top-right {
  background-color: $blue-50;
  z-index: 2;
  position: absolute;

  width: 1rem;
  height: 1rem;
  right: -0.5rem;
  top: -0.625rem;
  border-radius: 50%;
}

.timeline__item__structure__sphere-bottom-left {
  background-color: $blue-50;
  z-index: 1;
  position: absolute;

  width: 1.25rem;
  height: 1.25rem;
  left: -0.73rem;
  bottom: -0.625rem;
  border-radius: 50%;
}

@media only screen and (max-width: 700px) {
  .timeline__entries {
    padding: 0;
  }
}

@media only screen and (max-width: 600px) {

  .timeline {
    padding: 2rem;
  }

  .timeline__item__date {
    position: absolute;
    top: 1.5rem;
    left: 1rem;
  }

  .timeline__item__date__month {
    hyphens: auto;
    max-width: 4rem;
  }

  .timeline .card-small-plain {
    width: 100%;
    margin-left: 1rem;
  }
}
