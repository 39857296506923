.menu-wrapper {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    padding: 3rem $sidesMobile;
    background-color: transparent;
    max-width: $maxWidth;
    margin-right: auto;
    margin-left: auto;
    width: 100%;
}

.logo {
    width: 15rem;
}

.menu-list>li:hover>.submenu {
  display: flex;
}

.submenu:hover {
  display: flex;
}

.submenu {
  display: none;
  position: absolute;
  top: 3rem;
  left: 1rem;
  width: 13rem;
  background-color: #ffffff;
  padding: 1rem;
  z-index: 667;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.05), 0 1px 2px 0 rgba(0,3,5,0.20);
  border-radius: 3px;

}

li:last-child>.submenu {
  top: 5rem;
  left: unset;
  right: 0rem;
  align-items: flex-end;
}

.submenu>a {
  text-decoration: none;
  color: $blue-200;
  font-size: 1rem;
}

.submenu>a:hover, .submenu .current_subpage_item {
  text-decoration: underline;
  text-underline-offset: 0.3em;
  text-decoration-thickness: 2px;
}

.menu-list {
    display: flex;
    justify-content: space-evenly;
    text-align: center;
    align-items: center;
    flex-direction: row;
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.menu-list>li {
    position: relative;
    width: fit-content;
    padding: 1rem 1rem;
}

.menu-list>li>a {
    @include font-stack(base);
    margin-left: auto;
    margin-right: auto;
}

.menu-list>li>a:link, .menu-list>li>a:visited  {
    text-decoration: none;
    color: $blue-200;
}

.menu-list>li:not(:last-child)>a:hover {
  border-bottom: 2.5px solid $blue;
}

li:not(:last-child) .current_page_item {
    border-bottom: 2.5px solid $blue;
}

.menu-list>li>.current_page_item {
  color: $blue-500 !important;
  @include font-stack(gamma);
}

.mobile-nav__element {
  display: none;
}

/**
 * For wide screens the last menu item appears as a button
 */
@media (min-width: 1024px) {
  .menu-list>li:last-child>a {
    @include buttonBlue;
    color: white;
    align-items: center;
    display: flex;
    text-align: center;
  }

  .menu-list > li:last-child {
    padding-right: 0;
  }

  .menu-list>.current_page_item:last-child {
      border-bottom: 0px;
  }

  .menu-list>li:last-child>.current_page_item {
      color: white !important;
  }
}

@media only screen and (max-width: 1024px) {
  .logo {
      width: 10rem;
  }
}

@media only screen and (max-width: 1024px) {
  main {
    margin-top: 6rem;
  }

  .submenu {
    position: relative;
    display: flex;

    top: unset;
    background: unset;
    box-shadow: unset;
    align-items: flex-end;
    width: 100%;

    padding-top: 0.5rem;

    padding-bottom: 0;
  }

  .menu-list {

    height: 70vh;
    width: 35vw;
    overflow-y: auto;

    gap: 1rem;
    text-align: end;
    flex-direction: column;
    justify-content: flex-start;
    padding-right: 1rem;

    padding-right: 7vw;
  }

  .menu {
    position: absolute;
    width: 60vw;
    height: 110vh;
    margin: -50px -50px 0 -40vw;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: $yellow;
    -webkit-font-smoothing: antialiased;
    transform-origin: 0% 0%;
    transform: translate(100%, 0);
    transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
  }

  .menu-list li {
    width: 100%;
    transition-delay: 2s;
  }

  li:last-child>.submenu {
    top: unset;
    left: unset;
    right: unset;
    align-items: flex-end;
    padding-right: 0;
  }

  .logo__link {
    margin-bottom: 0;
    line-height: 0;
  }

  .menu-list > li {
    padding: 0;
  }

  .menu-wrapper {
    position: fixed;
    top: 0;
    background-color: white;
    z-index: 667;
    padding: 1.5rem $sidesMobile;
    box-shadow: $fourSideShadow;

  }

  .mobile-nav__element {
    display: block;
  }

  .menu-list > li > a {
    text-decoration: none;
    transition: 200ms;
    opacity: 1;
    @include font-stack(beta);
    color: $blue-300;
  }

  .submenu > a {
    color: $blue-300;
  }

  .menu-list>li>a:link, .menu-list>li>a:visited  {
      text-decoration: none;
      color: $blue-300;
  }

  .current_page_item {
      border-bottom: 2.5px solid $blue;
  }

  .menu-list>li>.current_page_item {
      color: $blue-500 !important;
      @include font-stack(beta);
      color: black;
  }

  ul {
    padding: 0;
    list-style-type: none;
  }

  nav {
    height: 3rem;
    width: 3rem;
  }

  #menuToggle {
    padding-top: 1rem;
    display: flex;
    flex-direction: column;
    position: relative;
    top: 0;
    left: 0;
    z-index: 1;
    -webkit-user-select: none;
    user-select: none;
  }

  #menuToggle input {
    display: flex;
    width: 40px;
    height: 32px;
    position: absolute;
    bottom: -1rem;
    cursor: pointer;
    opacity: 0;
    z-index: 2;
  }

  #menuToggle span {
    display: flex;
    width: 29px;
    height: 2px;
    margin-bottom: 5px;
    margin-left: 0.5rem;
    position: relative;
    background: $blue-500;
    border-radius: 3px;
    z-index: 1;
    transform-origin: 5px 0px;
    transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                opacity 0.55s ease;
  }

  #menuToggle span:first-child {
    transform-origin: 0% 0%;
  }

  #menuToggle span:nth-last-child(2) {
    transform-origin: 0% 100%;
  }

  input:checked ~ .menu {
    box-shadow: 0 0 20px rgb(0, 0, 0, 35%);
  }

  #menuToggle input:checked ~ span {
    opacity: 1;
    transform: rotate(45deg) translate(-3px, -1px);
    background: #36383F;
  }

  #menuToggle input:checked ~ span:nth-last-child(3) {
    opacity: 0;
    transform: rotate(0deg) scale(0.2, 0.2);
  }

  #menuToggle input:checked ~ span:nth-last-child(2) {
    transform: rotate(-45deg) translate(0, -1px);
  }

  #menuToggle input:checked ~ ul {
    transform: none;
  }
}

@media only screen and (max-width: 768px) {
  main {
    margin-top: 2.5rem;
  }
}

@media (max-width: 551px) {
  .menu {
    width: 80vw;
    margin: -50px -50px 0 -60vw;
  }

  .menu-list {
    width: 50vw;
    padding-right: 6vw;
  }
}

@media (max-width: 400px) {
  .menu {
    width: 90vw;
    margin: -50px -50px 0 -65vw;
  }
}

@media (max-width: 365px) {
  .menu {
    width: 90vw;
  }

  .menu-list {
    width: 80vw;
    padding-right: 20vw;
  }
}

