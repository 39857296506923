/* base */
@font-face {
  font-family: "PT Sans", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
  letter-spacing: 0;
  text-transform: none;
  src: url("../PTSans-Regular.a6f4278d.ttf");
}
/* alpha */
@font-face {
  font-family: "PT Sans Caption", sans-serif;
  font-size: 2.2rem;
  line-height: 1.25rem;
  font-weight: 600;
  letter-spacing: 0;
  text-transform: none;
  src: url("../PTSansCaption-Bold.2715dacd.ttf");
}
/* beta */
@font-face {
  font-family: "PT Sans Caption", sans-serif;
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 600;
  letter-spacing: 0;
  text-transform: none;
  src: url("../PTSans-Bold.295936c9.ttf");
}
/* gamma */
@font-face {
  font-family: "PT Sans", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 600;
  letter-spacing: 0;
  text-transform: none;
  src: url("../PTSans-Bold.295936c9.ttf");
}
/* delta */
@font-face {
  font-family: "PT Sans", sans-serif;
  font-size: 0.8333rem;
  line-height: 1.5rem;
  font-weight: 400;
  letter-spacing: 0;
  text-transform: none;
  src: url("../PTSans-Regular.a6f4278d.ttf");
}
/* epsilon */
@font-face {
  font-family: "PT Sans", sans-serif;
  font-size: 0.8333rem;
  line-height: 1.5rem;
  font-weight: 600;
  letter-spacing: 0;
  text-transform: none;
  src: url("../PTSans-Bold.295936c9.ttf");
}
/* zeta */
@font-face {
  font-family: "PT Sans Caption", sans-serif;
  font-size: 0.777rem;
  line-height: 1rem;
  font-weight: 400;
  letter-spacing: 0rem;
  text-transform: none;
  src: url("../PTSansCaption-Bold.2715dacd.ttf");
}
:root {
	/* added by fgp */
	--has-fgp: initial;
	--element-has-fgp: initial;
	--parent-has-fgp: initial;
}
* {
  box-sizing: border-box;
}
html {
  font-size: 16px;
  font-family: "PT Sans", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0;
  text-transform: none;
  color: #203141;
  scroll-behavior: smooth;
}
@media (min-width: 1024px) {
  html {
    font-size: 18px;
  }
}
body {
  margin: 0;
  position: inherit;
  overflow-x: hidden;
}
h1, p, ul {
  margin-top: 0;
}
a:visited, a:link {
  text-decoration: none;
}
a:-webkit-any-link {
  text-decoration: none;
}
.blocks {
  width: 100%;
}
.layout {
  display: flex;
}
.layout__row {
  flex-direction: row;
}
.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}
.flex-row-plain {
  display: flex;
  flex-direction: row;
}
.flex-row-sb {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.flex-column-plain {
  display: flex;
  flex-direction: column;
  position: relative;
}
.mb {
  margin-bottom: 1rem;
}
input[type=text],
input[type=email],
input[type=number],
input[type=tel],
textarea {
  border-radius: 4px;
  background-color: #F0F2F5;
  font-family: "PT Sans", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0;
  text-transform: none;
  color: #203141;
  border: 0px;
  padding: 1rem;
}
input[type=text]:focus, input[type=text]:active,
input[type=email]:focus,
input[type=email]:active,
input[type=number]:focus,
input[type=number]:active,
input[type=tel]:focus,
input[type=tel]:active,
textarea:focus,
textarea:active {
  outline-color: #8FA9C2;
}
textarea {
  resize: none;
  justify-content: start;
}
.form-label__optional {
  color: #666666;
}
.input--error, .textarea--error {
  background-color: #FEE6E6 !important;
}
.input--error:focus, .input--error:active, .textarea--error:focus, .textarea--error:active {
  outline-color: #D86464 !important;
}
.error-icon {
  position: absolute;
  filter: invert(46%) sepia(20%) saturate(1788%) hue-rotate(313deg) brightness(105%) contrast(69%);
  top: 3rem;
  right: 1rem;
  width: 1rem;
  height: 1rem;
}
.input-icon {
  position: absolute;
  filter: invert(72%) sepia(5%) saturate(1527%) hue-rotate(168deg) brightness(91%) contrast(90%);
  top: 3rem;
  right: 1rem;
  width: 1rem;
  height: 1rem;
}
input {
  position: relative;
  margin-bottom: 1rem;
  width: 100%;
}
label {
  font-family: "PT Sans", sans-serif;
  font-weight: 400;
  font-size: 0.8333rem;
  line-height: 1.5rem;
  letter-spacing: 0;
  text-transform: none;
  color: #203141;
  margin-bottom: 0.25rem;
  margin-left: 0.25rem;
}
/**
 * Hide number input controls for multiple browsers
 */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
  /* Firefox */
}
.input--error {
  padding-right: 2.5rem !important;
}
/**
 * Prevent certain browsers from colouring the inputs when autofilling
 */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
}
.select {
  position: relativ;
}
.select__input {
  appearance: none;
  -webkit-appearance: none;
  width: 100%;
  border-radius: 4px;
  background-color: #F0F2F5;
  font-family: "PT Sans", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0;
  text-transform: none;
  color: #203141;
  border: 0px;
  padding: 1rem;
}
.select__input::-ms-expand {
  display: none;
}
.select__input > option:disabled {
  display: none;
}
.select__input:focus, .select__input:active {
  outline-color: #8FA9C2;
}
.select__icon {
  filter: invert(38%) sepia(17%) saturate(1010%) hue-rotate(168deg) brightness(94%) contrast(87%);
  height: auto;
  width: 1.5rem;
  position: absolute;
  bottom: 0.9rem;
  right: 1rem;
}
.select__hidden {
  display: none;
}
.radio {
  display: inline-flex;
  cursor: pointer;
  align-items: center;
  font-family: "PT Sans", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0;
  text-transform: none;
  color: #203141;
}
.radio__input {
  display: none;
}
.radio__radio {
  width: 1.75rem;
  height: 1.75rem;
  border: 5px solid #F0F2F5;
  background-color: #F0F2F5;
  border-radius: 50%;
  margin-right: 1rem;
  box-sizing: border-box;
  padding: 2px;
}
.radio__radio::after {
  content: "";
  width: 100%;
  height: 100%;
  display: block;
  background: #496783;
  border-radius: 50%;
  transform: scale(0);
  transition: transform 0.15s;
}
.radio__input:checked + .radio__radio::after {
  transform: scale(1);
}
.radio__radio--error {
  background-color: #FEE6E6;
  border: 5px solid #FEE6E6;
}
.tooltip__text {
  background-color: #FFFFFF;
  color: #203141;
  border-radius: 30.38px;
  padding: 15px;
  box-shadow: 0 1px 2px 0 rgba(1, 3, 4, 0.15), 0 10px 15px 0 rgba(1, 3, 4, 0.05), inset 0 1px 0 0 #F5F9FC;
  display: inline-block;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  cursor: pointer;
  font-family: "PT Sans", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0;
  text-transform: none;
  color: #203141;
  width: -moz-fit-content;
  width: fit-content;
  overflow: hidden;
  position: relative;
  transition: background-color 0.6s ease;
  display: none;
  overflow: unset;
  z-index: 100;
}
.tooltip__text:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  top: 50%;
  left: 50%;
  transform-style: flat;
  transform: translate3d(-50%, -50%, 0);
  background: rgba(255, 255, 255, 0.2);
  border-radius: 100%;
  transition: width 0.3s ease, height 0.3s ease;
}
.tooltip__text:focus, .tooltip__text:hover {
  background: #f2f2f2;
}
.tooltip__text:active:after {
  width: 300px;
  height: 300px;
}
.tooltip:hover > .tooltip__text {
  display: block;
}
.tooltip > a:link, .tooltip > a:visited {
  text-decoration: none;
}
.alignRight {
  margin-left: 5rem;
}
.alignLeft {
  margin-left: -12rem;
}
.tooltip__text::before {
  content: "";
  position: absolute;
  top: 0.6rem;
  width: 0;
  height: 0;
  transition: border-right 0.6s ease;
  border-top: 1rem solid transparent;
  border-bottom: 1rem solid transparent;
}
.tooltip__text:hover::before {
  border-bottom: 1rem solid transparent;
  border-top: 1rem solid transparent;
}
.alignRight::before {
  left: 0;
  margin-left: -1rem;
  border-right: 1.5rem solid white;
}
.alignLeft::before {
  right: 0;
  margin-right: -1rem;
  border-left: 1.5rem solid white;
}
.alignRight:hover::before {
  border-right-color: #f2f2f2;
}
.alignLeft:hover::before {
  border-left-color: #f2f2f2;
}
.card-small {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05), 0 1px 2px 0 rgba(0, 3, 5, 0.2);
  border-radius: 3px;
  padding: 1.5rem;
  cursor: pointer;
  margin-bottom: 1rem;
  max-width: 30rem;
  transition: background-color 0.6s ease;
  overflow: hidden;
  position: relative;
}
.card-small:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  top: 50%;
  left: 50%;
  transform-style: flat;
  transform: translate3d(-50%, -50%, 0);
  background: rgba(240, 242, 245, 0.2);
  border-radius: 100%;
  transition: width 0.3s ease, height 0.3s ease;
}
.card-small:focus, .card-small:hover {
  background: #f2f2f2;
}
.card-small:active:after {
  width: 300px;
  height: 300px;
}
.card-small__content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.card-small__header {
  font-family: "PT Sans", sans-serif;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0;
  text-transform: none;
  color: #203141;
  color: #203141 !important;
  margin: 0;
}
.card-small__description {
  color: #496783 !important;
  margin: 0;
}
.card-small__icon {
  width: 1rem;
  filter: invert(8%) sepia(47%) saturate(3049%) hue-rotate(191deg) brightness(91%) contrast(102%);
}
.card-small-plain {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05), 0 1px 2px 0 rgba(0, 3, 5, 0.2);
  border-radius: 3px;
  padding: 1.5rem;
  margin-bottom: 1rem;
  max-width: 30rem;
  overflow: hidden;
  position: relative;
}
.text-block {
  padding: 2rem 0 2rem 2rem;
  position: relative;
  width: 100vw;
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
}
.text-block__text {
  width: 50%;
  font-family: "PT Sans", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0;
  text-transform: none;
  color: #203141;
}
@media only screen and (max-width: 600px) {
  .text-block {
    padding: 2rem 2rem;
    flex-direction: column;
  }

  .text-block__text {
    width: 100%;
    order: 1;
  }
}
.link-block {
  position: absolute;
  top: 48rem;
  right: 0;
  z-index: 666;
  margin: 0 2rem;
  border-radius: 3px;
  background-color: #FCC300;
  height: 16rem;
  width: 16rem;
  overflow: hidden;
  padding: 2rem;
  box-shadow: 0 1px 2px 0 rgba(1, 3, 4, 0.1), 0 5px 10px 0 rgba(1, 3, 4, 0.05);
}
.link-block__inside > * {
	/* added by fgp */
	--parent-has-fgp: !important;
	--element-has-fgp: initial;
	pointer-events: var(--parent-has-fgp) auto;
	--fgp-parent-gap-row: 1rem;
	--fgp-margin-top: var(--parent-has-fgp) calc(var(--fgp-gap-row) + var(--orig-margin-top, 0px));
	margin-top: var(--fgp-margin-top);
	--fgp-parent-gap-column: 1rem;
	--fgp-margin-left: var(--parent-has-fgp) calc(var(--fgp-gap-column) + var(--orig-margin-left, 0px));
	margin-left: var(--fgp-margin-left);
}
.link-block__inside > * > * {
	/* added by fgp */
	--parent-has-fgp: initial;
	--fgp-parent-gap-row: initial;
	--fgp-parent-gap-column: initial;
}
.link-block__inside {
	/* added by fgp */
	--fgp-height: var(--element-has-fgp) calc(100% + var(--fgp-gap-row, 0%));
	--has-fgp: ;
	--element-has-fgp: ;
	pointer-events: var(--has-fgp) none;
	--fgp-gap-row: 1rem;
	--fgp-margin-top: var(--has-fgp) calc(var(--fgp-parent-gap-row, 0px) / (1 + var(--fgp--parent-gap-as-decimal, 0)) - var(--fgp-gap-row) + var(--orig-margin-top, 0px)) !important;
	--fgp-gap-column: 1rem;
	--fgp-margin-left: var(--has-fgp) calc(var(--fgp-parent-gap-column, 0px) / (1 + var(--fgp--parent-gap-as-decimal, 0)) - var(--fgp-gap-column) + var(--orig-margin-left, 0px)) !important;
}
.link-block__inside {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	cursor: pointer;
	--fgp-gap: var(--has-fgp, 1rem);
	gap: var(--fgp-gap, 0px);
	height: var(--fgp-height, 100%);
	margin-top: var(--fgp-margin-top, var(--orig-margin-top));
	margin-left: var(--fgp-margin-left, var(--orig-margin-left));
}
.link-block__text {
  position: relative;
}
.link-block__icon {
  height: 1rem;
  width: 1rem;
  position: absolute;
  bottom: 0.25rem;
  right: 0;
}
.link-block__image {
  width: 80%;
  height: auto;
}
.link-block__text {
  width: 90%;
  margin: 0;
  color: black !important;
  font-family: "PT Sans", sans-serif;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0;
  text-transform: none;
  color: #203141;
}
@media only screen and (max-width: 1024px) {
  .link-block {
    top: 41.5rem;
  }
}
@media only screen and (max-width: 768px) {
  .link-block {
    position: fixed;
    bottom: 2rem;
    height: -moz-fit-content;
    height: fit-content;
    margin: 2rem;
    top: unset;
  }

  .link-block__text {
    width: 100%;
  }

  .link-block__image {
    display: none;
  }
}
@media only screen and (max-width: 600px) {
  .link-block {
    position: unset;
    z-index: 1;
    left: unset;
    top: unset;
    padding: 2rem 2rem 1rem 2rem;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2rem;
  }

  .link-block__inside {
    gap: 2rem;
  }

  .link-block__image {
    display: block;
  }
}
.image-with-text {
  padding: 2rem 2rem;
  width: 100vw;
  max-width: 1400px;
  margin-right: auto;
  margin-left: auto;
}
.image-with-text__image {
  width: 60%;
}
.image-with-text__text {
  position: relative;
  font-family: "PT Sans", sans-serif;
  font-weight: 600;
  font-size: 0.8333rem;
  line-height: 1.5rem;
  letter-spacing: 0;
  text-transform: none;
  color: #203141;
  width: 35%;
}
.image-with-text__text:after {
  position: absolute;
  content: "";
  width: 4rem;
  height: 4px;
  background-color: #FCC300;
  left: 0;
  margin-top: 2rem;
}
@media only screen and (max-width: 768px) {
  .image-with-text__text {
    width: 50%;
  }
}
@media only screen and (max-width: 600px) {
  .image-with-text {
    margin: 2rem 2rem 3rem 2rem;
    width: unset;
    padding: 0;
  }

  .image-with-text__image {
    width: 100%;
    text-align: center;
  }

  .image-with-text__text {
    width: 80%;
  }
}
.slideshow {
  background-color: #203141;
  position: relative;
  width: 100%;
  width: 100vw;
}
.slideshow__wrapper {
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
  padding: 3rem 0;
  overflow: hidden;
}
.slideshow__content {
  width: 50%;
  margin: auto;
}
.slideshow__list > * {
	/* added by fgp */
	--parent-has-fgp: !important;
	--element-has-fgp: initial;
	pointer-events: var(--parent-has-fgp) auto;
	--fgp-parent-gap-row: 1rem;
	--fgp-margin-top: var(--parent-has-fgp) calc(var(--fgp-gap-row) + var(--orig-margin-top, 0px));
	margin-top: var(--fgp-margin-top);
	--fgp-parent-gap-column: 1rem;
	--fgp-margin-left: var(--parent-has-fgp) calc(var(--fgp-gap-column) + var(--orig-margin-left, 0px));
	margin-left: var(--fgp-margin-left);
}
.slideshow__list > * > * {
	/* added by fgp */
	--parent-has-fgp: initial;
	--fgp-parent-gap-row: initial;
	--fgp-parent-gap-column: initial;
}
.slideshow__list {
	/* added by fgp */
	--fgp-width: var(--element-has-fgp) calc(100% + var(--fgp-gap-column, 0%));
	--has-fgp: ;
	--element-has-fgp: ;
	pointer-events: var(--has-fgp) none;
	--fgp-gap-row: 1rem;
	--fgp-margin-top: var(--has-fgp) calc(var(--fgp-parent-gap-row, 0px) / (1 + var(--fgp--parent-gap-as-decimal, 0)) - var(--fgp-gap-row) + var(--orig-margin-top, 0px)) !important;
	--fgp-gap-column: 1rem;
	--fgp-margin-left: var(--has-fgp) calc(var(--fgp-parent-gap-column, 0px) / (1 + var(--fgp--parent-gap-as-decimal, 0)) - var(--fgp-gap-column) + var(--orig-margin-left, 0px)) !important;
}
.slideshow__list {
	display: flex;
	flex-direction: row;
	--fgp-gap: var(--has-fgp, 1rem);
	gap: var(--fgp-gap, 0px);
	width: var(--fgp-width, 100%);
	margin-top: var(--fgp-margin-top, var(--orig-margin-top));
	margin-left: var(--fgp-margin-left, var(--orig-margin-left));
}
.slideshow__image {
  max-width: 44rem;
  max-height: 30rem;
  filter: brightness(35%);
  -o-object-fit: cover;
     object-fit: cover;
}
.slideshow__text {
  position: relative;
  font-family: "PT Sans", sans-serif;
  font-weight: 600;
  font-size: 0.8333rem;
  line-height: 1.5rem;
  letter-spacing: 0;
  text-transform: none;
  color: #203141;
  width: 50%;
  color: white;
}
.slideshow__text:after {
  position: absolute;
  content: "";
  width: 4rem;
  height: 4px;
  background-color: #FCC300;
  left: 0;
  margin-top: 2rem;
}
.slider__back, .slider__forward {
  position: absolute;
  z-index: 2;
  top: 40%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1.5rem;
  border-radius: 2rem;
  background-color: #F9E08A;
}
.slider__back:hover, .slider__back :focus, .slider__forward:hover, .slider__forward :focus {
  background: #f7d768;
}
.slider__back {
  left: 20%;
}
.slider__forward {
  right: 20%;
}
.slider__icon {
  width: 1rem;
  height: auto;
  filter: invert(14%) sepia(67%) saturate(344%) hue-rotate(167deg) brightness(99%) contrast(93%);
}
@media only screen and (max-width: 1024px) {
  .slider__back {
    left: 10%;
  }

  .slider__forward {
    right: 10%;
  }

  .slideshow__wrapper {
    width: 100%;
  }

  .slideshow__content {
    width: 70%;
  }

  .slideshow__text {
    width: 70%;
  }
}
@media only screen and (max-width: 768px) {
  .slider__back {
    left: 2%;
  }

  .slider__forward {
    right: 2%;
  }
}
@media only screen and (max-width: 500px) {
  .slideshow__wrapper {
    padding: 1.5rem 0;
  }

  .slider__back, .slider__forward {
    top: 33%;
  }
}
.appetizer__wrapper {
  width: 100%;
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
}
.appetizer__wrapper h1 {
  margin-top: 4rem;
  margin-left: 2rem;
}
.appetizer__wrapper__inside > * {
	/* added by fgp */
	--parent-has-fgp: !important;
	--element-has-fgp: initial;
	pointer-events: var(--parent-has-fgp) auto;
	--fgp-parent-gap-row: 2rem;
	--fgp-margin-top: var(--parent-has-fgp) calc(var(--fgp-gap-row) + var(--orig-margin-top, 0px));
	margin-top: var(--fgp-margin-top);
	--fgp-parent-gap-column: 2rem;
	--fgp-margin-left: var(--parent-has-fgp) calc(var(--fgp-gap-column) + var(--orig-margin-left, 0px));
	margin-left: var(--fgp-margin-left);
}
.appetizer__wrapper__inside > * > * {
	/* added by fgp */
	--parent-has-fgp: initial;
	--fgp-parent-gap-row: initial;
	--fgp-parent-gap-column: initial;
}
.appetizer__wrapper__inside {
	/* added by fgp */
	--has-fgp: ;
	--element-has-fgp: ;
	pointer-events: var(--has-fgp) none;
	--fgp-gap-row: 2rem;
	--fgp-margin-top: var(--has-fgp) calc(var(--fgp-parent-gap-row, 0px) / (1 + var(--fgp--parent-gap-as-decimal, 0)) - var(--fgp-gap-row) + var(--orig-margin-top, 0px)) !important;
	--fgp-gap-column: 2rem;
	--fgp-margin-left: var(--has-fgp) calc(var(--fgp-parent-gap-column, 0px) / (1 + var(--fgp--parent-gap-as-decimal, 0)) - var(--fgp-gap-column) + var(--orig-margin-left, 0px)) !important;
}
.appetizer__wrapper__inside {
	display: flex;
	flex-direction: row;
	--fgp-gap: var(--has-fgp, 2rem);
	gap: var(--fgp-gap, 0px);
	position: relative;
	justify-content: center;
	align-items: center;
	margin-top: var(--fgp-margin-top, var(--orig-margin-top));
	margin-left: var(--fgp-margin-left, var(--orig-margin-left));
}
.appetizer, .appetizer__image {
  margin: 5rem 2rem;
}
.appetizer {
  display: flex;
  flex-direction: column;
  max-width: 20rem;
}
.appetizer__text {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
}
.appetizer__topic {
  font-family: "PT Sans", sans-serif;
  font-weight: 600;
  font-size: 0.8333rem;
  line-height: 1.5rem;
  letter-spacing: 0;
  text-transform: none;
  color: #203141;
  text-transform: uppercase !important;
  margin-bottom: 0.25rem;
  letter-spacing: 0.1em;
}
.appetizer__topic, .appetizer__headline, .appetizer__text {
  color: #001A33;
}
.appetizer__headline {
  font-family: "PT Sans Caption", sans-serif;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 2rem;
  letter-spacing: 0;
  text-transform: none;
  color: #203141;
  -webkit-hyphens: auto;
          hyphens: auto;
}
.appetizer__button {
  margin-top: 0;
  background-color: #E2EBF4;
  border-radius: 30.38px;
  padding: 15px;
  box-shadow: 0 1px 2px 0 rgba(1, 3, 4, 0.15), 0 10px 15px 0 rgba(1, 3, 4, 0.05), inset 0 1px 0 0 #E9F0F6;
  display: inline-block;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  cursor: pointer;
  font-family: "PT Sans", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0;
  text-transform: none;
  color: #203141;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
  transition: background-color 0.6s ease;
  overflow: hidden;
}
.appetizer__button:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  top: 50%;
  left: 50%;
  transform-style: flat;
  transform: translate3d(-50%, -50%, 0);
  background: rgba(255, 255, 255, 0.2);
  border-radius: 100%;
  transition: width 0.3s ease, height 0.3s ease;
}
.appetizer__button:focus, .appetizer__button:hover {
  background: #c8d9ea;
}
.appetizer__button:active:after {
  width: 300px;
  height: 300px;
}
.appetizer__image {
  position: relative;
  width: 21rem;
  height: 21rem;
  background-color: #FCC300;
  border-radius: 50%;
}
.appetizer__image .contact-person__image {
  transform: scale(1);
}
.appetizer__image > img {
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  z-index: 4;
  border-radius: 50%;
  width: 20rem;
  height: auto;
  box-shadow: 0 1px 2px 0 rgba(1, 3, 4, 0.1), 0 5px 10px 0 rgba(1, 3, 4, 0.05), inset 0 1px 0 0 #f5f9fc;
}
.appetizer__image__mask__left {
  position: absolute;
  z-index: 2;
  width: 50%;
  height: 100%;
  background-color: white;
}
.appetizer__image__mask__right {
  position: absolute;
  z-index: 2;
  right: 0;
  width: 50%;
  height: 100%;
  background-color: white;
}
.appetizer__image__mask__bottom {
  position: absolute;
  z-index: 2;
  bottom: 0;
  width: 100%;
  height: 50%;
  background-color: white;
}
.appetizer__image__mask__top {
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 50%;
  background-color: white;
}
.appetizer__image__mask {
  position: absolute;
  bottom: 0.5rem;
  left: 0.5rem;
  z-index: 3;
  width: 20rem;
  height: 20rem;
  background-color: white;
  border-radius: 50%;
}
@media only screen and (max-width: 768px) {
  .appetizer__wrapper__inside {
    flex-direction: column;
  }

  .appetizer__image {
    width: 15rem;
    height: 15rem;
    order: 1;
    margin-bottom: 2rem;
    margin-top: 2rem;
  }

  .appetizer {
    order: 2;
    margin-top: 0;
    margin-bottom: 2rem;
    align-items: center;
    text-align: center;
  }

  .appetizer__image > img {
    width: 14rem;
    height: auto;
  }

  .appetizer__image__mask {
    width: 14rem;
    height: 14rem;
  }
}
@media only screen and (max-width: 400px) {
  .appetizer {
    padding: 0 2rem;
  }
}
.accordion__wrapper {
  width: 100%;
  max-width: 1400px;
  margin-right: auto;
  margin-left: auto;
}
.accordion {
  max-width: 40rem;
  position: relative;
  margin: 2rem 2rem;
}
.accordion__item {
  padding: 1rem 1.5rem;
  background-color: #F0F2F5;
  box-shadow: 0 1px 2px 0 rgba(1, 3, 4, 0.15), 0 10px 15px 0 rgba(1, 3, 4, 0.05), inset 0 1px 0 0 #E9F0F6;
}
.accordion__item--first {
  border-radius: 3px 3px 0 0;
}
.accordion__item--last {
  border-radius: 0 0 3px 3px;
}
.accordion__item__header {
  cursor: pointer;
}
.accordion__item__icon {
  width: 0.6rem;
  height: auto;
  margin-right: 1rem;
  filter: invert(38%) sepia(17%) saturate(1010%) hue-rotate(168deg) brightness(94%) contrast(87%);
}
.accordion__item__headline {
  font-family: "PT Sans", sans-serif;
  font-weight: 600;
  font-size: 0.8333rem;
  line-height: 1.5rem;
  letter-spacing: 0;
  text-transform: none;
  color: #203141;
  margin: 0;
}
.accordion__item__text {
  opacity: 0;
  height: 0;
  padding: 0;
  margin: 0;
  overflow: hidden;
}
.accordion__item--open {
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  margin-bottom: 1rem;
  margin-top: 1rem;
  position: relative;
}
.accordion__item--open:after {
  content: "";
  position: absolute;
  top: 0;
  left: 1.5rem;
  height: 4px;
  width: 4rem;
  background-color: #FCC300;
}
@media only screen and (max-width: 600px) {
  .accordion {
    margin: 2rem 2rem;
  }
}
.job-banner {
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
  padding: 2rem 2rem;
}
.job-banner__wrapper {
  width: 100vw;
  background-color: #FCC300;
}
.job-banner__headline {
  font-family: "PT Sans Caption", sans-serif;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 2rem;
  letter-spacing: 0;
  text-transform: none;
  color: #203141;
}
@media only screen and (max-width: 600px) {
  .job-banner {
    padding: 2rem 2rem;
  }
}
.contact-form__wrapper {
  width: 100%;
  max-width: 1400px;
  margin-right: auto;
  margin-left: auto;
}
.contact-form {
  width: 60%;
  padding: 2rem 2rem;
}
.honeypot {
  position: absolute;
  left: -9999px;
}
.contact-form__field {
  border-bottom: 1px solid #F0F2F5;
  padding: 1rem 0;
  width: 100%;
  gap: 1rem;
}
.contact-form__field__description > h1 {
  font-family: "PT Sans Caption", sans-serif;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 2rem;
  letter-spacing: 0;
  text-transform: none;
  color: #203141;
  margin-bottom: 0.5rem;
}
.contact-form__field__description {
  width: 40%;
}
.contact-form__field__input {
  width: 60%;
}
.submit {
  background-color: #004B92;
  border-radius: 30.38px;
  padding: 1rem;
  box-shadow: 0 10px 15px 0 rgba(1, 3, 4, 0.05), 0 1px 2px 0 rgba(1, 3, 4, 0.2), inset 0 1px 0 0 #0057A9;
  display: inline-block;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  cursor: pointer;
  font-family: "PT Sans", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0;
  text-transform: none;
  color: #203141;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
  transition: background-color 0.6s ease;
  overflow: hidden;
  color: white;
  outline: none;
  border: 0px;
  margin-top: 1rem;
}
.submit:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  top: 50%;
  left: 50%;
  transform-style: flat;
  transform: translate3d(-50%, -50%, 0);
  background: rgba(255, 255, 255, 0.2);
  border-radius: 100%;
  transition: width 0.3s ease, height 0.3s ease;
}
.submit:focus, .submit:hover {
  background: #00396e;
}
.submit:active:after {
  width: 0;
  height: 0;
}
.town {
  width: 70%;
}
.postalCode {
  width: 30%;
}
.postal-town {
  gap: 1rem;
}
.surname {
  width: 50%;
}
.name {
  width: 50%;
}
.name-surname {
  gap: 1rem;
  width: 100%;
}
.pronoun {
  width: 100%;
  gap: 2rem;
}
.alert--success {
  background-color: #CBF4AC;
}
.alert--error {
  background-color: #F4BABA;
}
.alert {
  border-radius: 15px;
  padding: 0.5rem 1rem;
  width: -moz-fit-content;
  width: fit-content;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  color: #203141;
}
.submit-wrapper {
  border-bottom: 0px;
}
@media only screen and (max-width: 1400px) {
  .contact-form {
    width: unset;
  }
}
@media only screen and (max-width: 768px) {
  .contact-form {
    width: 100vw;
  }

  .contact-form__field {
    flex-direction: column !important;
  }

  .contact-form__field__description {
    width: 100%;
  }

  .contact-form__field__input {
    width: 100%;
  }
}
@media only screen and (max-width: 600px) {
  .contact-form {
    padding: 2rem 2rem;
  }

  .pronoun {
    gap: 1rem;
  }
}
.card-grid {
  background-color: #F0F2F5;
  width: 100%;
}
.card-grid__wrapper {
  padding: 4rem 2rem;
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
}
.card-grid__header {
  font-family: "PT Sans Caption", sans-serif;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 2rem;
  letter-spacing: 0;
  text-transform: none;
  color: #203141;
  margin-bottom: 2rem;
}
.card-grid__grid > * {
	/* added by fgp */
	--parent-has-fgp: !important;
	--element-has-fgp: initial;
	--orig-margin-top: initial;
	--orig-margin-right: initial;
	--orig-margin-bottom: initial;
	--orig-margin-left: initial;
	pointer-events: var(--parent-has-fgp) auto;
	--fgp-parent-gap-row: 1rem;
	--fgp-margin-top: var(--parent-has-fgp) calc(var(--fgp-gap-row) + var(--orig-margin-top, 0px));
	margin-top: var(--fgp-margin-top);
	--fgp-parent-gap-column: 1rem;
	--fgp-margin-left: var(--parent-has-fgp) calc(var(--fgp-gap-column) + var(--orig-margin-left, 0px));
	margin-left: var(--fgp-margin-left);
}
.card-grid__grid > * > * {
	/* added by fgp */
	--parent-has-fgp: initial;
	--fgp-parent-gap-row: initial;
	--fgp-parent-gap-column: initial;
}
.card-grid__grid {
	/* added by fgp */
	--has-fgp: ;
	--element-has-fgp: ;
	pointer-events: var(--has-fgp) none;
	--fgp-gap-row: 1rem;
	--fgp-margin-top: var(--has-fgp) calc(var(--fgp-parent-gap-row, 0px) / (1 + var(--fgp--parent-gap-as-decimal, 0)) - var(--fgp-gap-row) + var(--orig-margin-top, 0px)) !important;
	--fgp-gap-column: 1rem;
	--fgp-margin-left: var(--has-fgp) calc(var(--fgp-parent-gap-column, 0px) / (1 + var(--fgp--parent-gap-as-decimal, 0)) - var(--fgp-gap-column) + var(--orig-margin-left, 0px)) !important;
}
.card-grid__grid {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	--fgp-gap: var(--has-fgp, 1rem);
	gap: var(--fgp-gap, 0px);
	--fgp-margin-top: initial;
	--fgp-margin-left: initial;
	--orig-margin-top: 0px;
	--orig-margin-right: 0px;
	--orig-margin-bottom: 0px;
	--orig-margin-left: 0px;
	margin: var(--fgp-margin-top, var(--orig-margin-top)) var(--orig-margin-right) var(--orig-margin-bottom) var(--fgp-margin-left, var(--orig-margin-left));
}
.card-grid__card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  width: 23%;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05), 0 1px 2px 0 rgba(0, 3, 5, 0.2);
  border-radius: 3px;
  padding: 1.5rem;
}
.card-grid__card::after {
  content: "";
  position: absolute;
  background-color: #FCC300;
  z-index: 5;
  top: 0;
  left: 1.5rem;
  width: 4rem;
  height: 0.25rem;
  border-radius: 0;
}
.card-grid__card__headline {
  font-family: "PT Sans", sans-serif;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0;
  text-transform: none;
  color: #203141;
  margin: 0;
  margin-bottom: 0.5rem;
  -webkit-hyphens: auto;
          hyphens: auto;
}
.card-grid__card__number {
  margin: 0;
  background-color: #E2EBF4;
  border-radius: 1rem;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0.25rem 1.5rem;
  text-align: center;
}
@media only screen and (max-width: 1490px) {
  .card-grid__card {
    min-width: 15rem;
  }
}
@media only screen and (max-width: 655px) {
  .card-grid__card {
    min-width: 100%;
  }
}
@media only screen and (max-width: 600px) {
  .card-grid {
    padding: 0;
    min-width: unset;
    width: 100%;
  }
}
.company__header {
  position: relative;
  height: 30rem;
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 2rem;
}
.company__header__title {
  position: absolute;
  width: 25rem;
  top: 19rem;
  left: 2rem;
  font-family: "PT Sans Caption", sans-serif;
  font-weight: 600;
  font-size: 2.2rem;
  line-height: 1.25rem;
  letter-spacing: 0;
  text-transform: none;
  color: #203141;
  color: #004B92;
  z-index: 3;
  line-height: 3rem;
}
.company__header__title::after {
  content: "";
  position: absolute;
  background-color: #FCC300;
  width: 60%;
  height: 8px;
  z-index: 4;
  margin-top: 1rem;
  left: 2rem;
  top: 100%;
}
#company__header__block {
  border-radius: 3px;
  position: absolute;
  background-color: #F0F2F5;
  height: 0;
  width: 30%;
  padding-bottom: 30%;
  z-index: 2;
  bottom: 0%;
  left: 10%;
}
.company__header__image {
  border-radius: 3px;
  background-color: #004B92;
  position: absolute;
  z-index: 1;
  height: 80%;
  width: 70%;
  -o-object-fit: cover;
     object-fit: cover;
  right: 0;
}
.company__header__mobile-background {
  display: none;
}
@media only screen and (max-width: 768px) {
  .company__header {
    margin-top: 6rem;
    display: flex;
    flex-direction: column;
    padding-left: 0;
    height: 100%;
  }

  #company__header__block {
    display: none;
    width: 100%;
    height: 10rem;
    left: 0;
    order: 2;
    padding-left: 0;
    padding-bottom: 0;
  }

  .company__header__image {
    border-radius: 0;
    width: 100%;
    height: auto;
    position: relative;
    order: 1;
  }

  .company__header__title {
    max-width: 35rem;
    width: 100%;
    left: unset;
    top: unset;
    position: relative;
    order: 2;
    padding: 2rem;
  }
  .company__header__title::after {
    top: unset;
    bottom: 0;
    left: 10%;
  }

  .company__header__mobile-background {
    display: block;
    position: absolute;
    bottom: 0;
    height: 20rem;
    width: 100%;
    background-color: #F0F2F5;
    order: 4;
  }
}
.contact-person__wrapper {
  background-color: #F0F2F5;
  width: 100vw;
}
.contact-person {
  max-width: 1400px;
  padding: 2rem 2rem 0rem 2rem;
  margin-left: auto;
  margin-right: auto;
}
#contact-person__flex-row {
  justify-content: flex-start;
  padding-bottom: 3rem;
  gap: 3rem;
}
.contact-person__header {
  font-family: "PT Sans Caption", sans-serif;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 2rem;
  letter-spacing: 0;
  text-transform: none;
  color: #203141;
  color: #203141;
  margin-bottom: 2rem;
}
.contact-person__name {
  font-family: "PT Sans", sans-serif;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0;
  text-transform: none;
  color: #203141;
  color: #203141;
  margin-bottom: 0rem;
}
.contact-person__phone, .contact-person__email {
  background-color: #E2EBF4;
  border-radius: 30.38px;
  padding: 15px;
  box-shadow: 0 1px 2px 0 rgba(1, 3, 4, 0.15), 0 10px 15px 0 rgba(1, 3, 4, 0.05), inset 0 1px 0 0 #E9F0F6;
  display: inline-block;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  cursor: pointer;
  font-family: "PT Sans", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0;
  text-transform: none;
  color: #203141;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
  transition: background-color 0.6s ease;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.contact-person__phone:after, .contact-person__email:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  top: 50%;
  left: 50%;
  transform-style: flat;
  transform: translate3d(-50%, -50%, 0);
  background: rgba(255, 255, 255, 0.2);
  border-radius: 100%;
  transition: width 0.3s ease, height 0.3s ease;
}
.contact-person__phone:focus, .contact-person__phone:hover, .contact-person__email:focus, .contact-person__email:hover {
  background: #c8d9ea;
}
.contact-person__phone:active:after, .contact-person__email:active:after {
  width: 300px;
  height: 300px;
}
.contact-person__icon {
  height: 1rem;
  padding-right: 1rem;
}
#contact-person__content > .flex-row {
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 1rem;
}
.contact-person__phone p, .contact-person__email p {
  margin: 0;
}
.contact-person__image-wrapper {
  position: relative;
  width: 11rem;
  min-width: 11rem;
  min-height: 11rem;
  height: 11rem;
  margin-left: -5px;
  background-color: #FCC300;
  border-radius: 50%;
}
.contact-person__image {
  position: absolute;
  z-index: 3;
  transform: scale(1);
  border-radius: 50%;
  width: 10rem;
  height: 10rem;
  -o-object-fit: cover;
     object-fit: cover;
}
.contact-person__image__container {
  z-index: 4;
  top: 0.5rem;
  left: 0.5rem;
  border-radius: 50%;
  width: 10rem;
  height: 10rem;
  overflow: hidden;
  display: inline-block;
  position: relative;
  box-shadow: 0 1px 2px 0 rgba(1, 3, 4, 0.3), 0 10px 15px 0 rgba(1, 3, 4, 0.2), inset 0 1px 0 0 #F5F9FC;
}
.contact-person__mask__left {
  position: absolute;
  z-index: 2;
  width: 50%;
  height: 100%;
  background-color: #F0F2F5;
}
.contact-person__mask__right {
  position: absolute;
  z-index: 2;
  right: 0;
  width: 50%;
  height: 100%;
  background-color: #F0F2F5;
}
.contact-person__mask__bottom {
  position: absolute;
  z-index: 2;
  bottom: 0;
  width: 100%;
  height: 50%;
  background-color: #F0F2F5;
}
.contact-person__mask__top {
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 50%;
  background-color: #F0F2F5;
}
@media only screen and (max-width: 768px) {
  .contact-person > .flex-row {
    flex-direction: column;
  }

  #contact-person__content {
    text-align: center;
  }

  #contact-person__flex-row {
    gap: 1.5rem;
  }

  #contact-person__content > .flex-row {
    align-items: center;
    justify-content: center;
  }
}
.quote {
  background-color: #F0F2F5;
  width: 100%;
}
.quote__wrapper {
  margin-left: auto;
  margin-right: auto;
  max-width: 1400px;
  padding: 2rem 2rem;
}
.quote__headline {
  font-family: "PT Sans Caption", sans-serif;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 2rem;
  letter-spacing: 0;
  text-transform: none;
  color: #203141;
}
.quote__entry {
  gap: 1.5rem;
  position: relative;
}
.quote__card {
  width: 35rem;
  margin-bottom: 0.5rem;
  min-height: 8rem;
  overflow: visible;
  flex-shrink: 1;
  cursor: default;
}
.quote__card:focus, .quote__card:hover {
  background: white;
}
.quote__card:active:after {
  width: 0;
  height: 0;
}
.quote__card__quotation__left, .quote__card__quotation__right {
  position: absolute;
  font-family: "PT Sans Caption", sans-serif;
  font-weight: 600;
  font-size: 2.2rem;
  line-height: 1.25rem;
  letter-spacing: 0;
  text-transform: none;
  color: #203141;
  color: #FCC300;
  font-size: 7rem;
  z-index: 3;
  margin: 0;
}
.quote__card__quotation__left {
  bottom: 1.85rem;
  left: -1.5rem;
}
.quote__card__quotation__right {
  top: 2.25rem;
  right: -1.5rem;
}
.quote__picture {
  margin-top: -2rem;
}
.quotes {
  margin-top: 3rem;
  padding: 2rem 1.5rem;
}
.quote__content {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.quote__credit {
  padding-right: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.quote__credit > .contact-person__name {
  font-family: "PT Sans", sans-serif;
  font-weight: 600;
  font-size: 0.8333rem;
  line-height: 1.5rem;
  letter-spacing: 0;
  text-transform: none;
  color: #203141;
  color: #FCC300;
  line-height: 1rem;
  font-size: 1rem;
}
.quote__credit > .contact-person__position {
  font-family: "PT Sans", sans-serif;
  font-weight: 400;
  font-size: 0.8333rem;
  line-height: 1.5rem;
  letter-spacing: 0;
  text-transform: none;
  color: #203141;
}
@media only screen and (max-width: 800px) {
  .quote__card {
    width: 100%;
  }

  .quote__entry {
    gap: 1.5rem;
  }
}
@media only screen and (max-width: 600px) {
  .quotes {
    margin-top: 0;
    padding-bottom: 0;
  }

  .quote__entry {
    margin-bottom: 7rem;
  }

  .quote__entry:last-child {
    margin-bottom: 4rem;
  }

  .quote__picture {
    position: absolute;
    width: 5.5rem;
    height: 5.5rem;
    min-width: 5.5rem;
    min-height: 5.5rem;
    z-index: 3;
    bottom: -4.5rem;
    right: 0rem;
  }

  .quote .contact-person__image__container {
    position: absolute;
    width: 5.5rem;
    height: 5.5rem;
    z-index: 3;
    bottom: -4.5rem;
    right: 0rem;
  }

  .quote__picture .contact-person__image {
    width: 5rem;
    height: 5rem;
    top: 0.25rem;
    left: 0.25rem;
  }

  .quote__card {
    max-width: none;
  }
}
.timeline {
  margin-left: auto;
  margin-right: auto;
  max-width: 1400px;
  height: 100%;
  padding: 4rem 2rem;
  position: relative;
}
.timeline__headline {
  margin-bottom: 3rem;
}
.timeline__entries {
  padding: 1rem 0 1rem 1rem;
}
.timeline .card-small-plain {
  flex-direction: column;
  height: -moz-fit-content;
  height: fit-content;
  margin-top: -0.25rem;
  width: 100%;
}
.timeline .card-small-plain:after {
  content: "";
  position: absolute;
  top: 0rem;
  left: 1.5rem;
  height: 4px;
  width: 8rem;
  background-color: #FCC300;
}
.timeline__item {
  height: 100%;
  position: relative;
  gap: 1rem;
}
.timeline__item__date {
  margin-top: -0.3rem;
  width: 100%;
  max-width: 5rem;
}
.timeline__item__date__year {
  margin: 0;
  line-height: 1.5rem;
}
.timeline__item__date__month {
  font-family: "PT Sans", sans-serif;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0;
  text-transform: none;
  color: #203141;
  color: #FCC300;
  margin: 0;
}
.timeline__item__structure {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 5rem;
  border-top: 5px solid #F0F2F5;
  border-left: 5px solid #F0F2F5;
  margin-top: 0.25rem;
  margin-bottom: -0.25rem;
}
.timeline__item__structure__sphere-top-left {
  background-color: #FCC300;
  z-index: 2;
  position: absolute;
  width: 1.5rem;
  height: 1.5rem;
  left: -0.855rem;
  top: -0.85rem;
  border-radius: 50%;
}
.timeline__item__structure__sphere-top-right {
  background-color: #F0F2F5;
  z-index: 2;
  position: absolute;
  width: 1rem;
  height: 1rem;
  right: -0.5rem;
  top: -0.625rem;
  border-radius: 50%;
}
.timeline__item__structure__sphere-bottom-left {
  background-color: #F0F2F5;
  z-index: 1;
  position: absolute;
  width: 1.25rem;
  height: 1.25rem;
  left: -0.73rem;
  bottom: -0.625rem;
  border-radius: 50%;
}
@media only screen and (max-width: 700px) {
  .timeline__entries {
    padding: 0;
  }
}
@media only screen and (max-width: 600px) {
  .timeline {
    padding: 2rem;
  }

  .timeline__item__date {
    position: absolute;
    top: 1.5rem;
    left: 1rem;
  }

  .timeline__item__date__month {
    -webkit-hyphens: auto;
            hyphens: auto;
    max-width: 4rem;
  }

  .timeline .card-small-plain {
    width: 100%;
    margin-left: 1rem;
  }
}
.custom-heading {
  color: #001A33;
  margin-bottom: 0;
}
.custom-heading__wrapper {
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
  padding: 3rem 2rem 0rem 2rem;
}
@media only screen and (max-width: 600px) {
  .custom-heading__wrapper {
    padding-top: 2rem;
  }

  .custom-heading {
    margin-top: 0;
  }
}
.lead-form .postalCode {
  width: 100%;
}
.lead-form .message {
  margin-bottom: 1rem;
}
.lead-form .town {
  width: 100%;
}
.lead-form .pronoun {
  padding-top: 1rem;
}
.honeypot {
  position: absolute;
  left: -9999px;
}
.spinner {
  position: relative;
  overflow: hidden;
  display: inline-block;
  margin-right: 0.25rem;
  margin-bottom: -0.1rem;
  font-size: 0.1rem;
  text-indent: -9999em;
  border-top: 1.5em solid rgba(255, 255, 255, 0.2);
  border-right: 1.5em solid rgba(255, 255, 255, 0.2);
  border-bottom: 1.5em solid rgba(255, 255, 255, 0.2);
  border-left: 1.5em solid white;
  transform: translateZ(0);
  animation: load8 1.1s infinite linear;
}
.spinner, .spinner::after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.is-hidden {
  display: none;
}
@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.privacy__wrapper {
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
  padding: 2rem;
  width: 100vw;
}
.privacy__button {
  background-color: #004B92;
  border-radius: 30.38px;
  padding: 1rem;
  box-shadow: 0 10px 15px 0 rgba(1, 3, 4, 0.05), 0 1px 2px 0 rgba(1, 3, 4, 0.2), inset 0 1px 0 0 #0057A9;
  display: inline-block;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  cursor: pointer;
  font-family: "PT Sans", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0;
  text-transform: none;
  color: #203141;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
  transition: background-color 0.6s ease;
  overflow: hidden;
  color: white;
  border: none;
}
.privacy__button:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  top: 50%;
  left: 50%;
  transform-style: flat;
  transform: translate3d(-50%, -50%, 0);
  background: rgba(255, 255, 255, 0.2);
  border-radius: 100%;
  transition: width 0.3s ease, height 0.3s ease;
}
.privacy__button:focus, .privacy__button:hover {
  background: #00396e;
}
.privacy__button:active:after {
  width: 0;
  height: 0;
}
.video-block {
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  position: relative;
  height: 30rem;
}
.video-block__title {
  position: absolute;
  width: 25rem;
  top: 19rem;
  left: 2rem;
  font-family: "PT Sans Caption", sans-serif;
  font-weight: 600;
  font-size: 2.2rem;
  line-height: 1.25rem;
  letter-spacing: 0;
  text-transform: none;
  color: #203141;
  color: #004B92;
  z-index: 3;
  line-height: 3rem;
}
.video-block__title::after {
  content: "";
  position: absolute;
  background-color: #FCC300;
  width: 60%;
  height: 8px;
  z-index: 4;
  margin-top: 1rem;
  left: 2rem;
  top: 100%;
}
#video-block__block {
  border-radius: 3px;
  position: absolute;
  background-color: #F0F2F5;
  height: 0;
  width: 30%;
  padding-bottom: 30%;
  z-index: 2;
  bottom: 0%;
  left: 10%;
}
.video-block__player {
  position: absolute;
  z-index: 1;
  height: 80%;
  width: 70%;
  -o-object-fit: cover;
     object-fit: cover;
  right: 0;
}
video {
  display: block;
  height: auto;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  max-height: 100%;
}
.video__thumbnail {
  left: 0;
  top: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  -o-object-fit: cover;
     object-fit: cover;
}
.video-block__player__button {
  z-index: 3;
  position: absolute;
  right: 1rem;
  bottom: 1rem;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  padding: 1rem;
  background-color: #004B92;
  transition: background-color 0.6s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}
.video-block__player__button img {
  z-index: 4;
  height: 100%;
  width: 100%;
  position: relative;
  filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(119deg) brightness(108%) contrast(103%);
}
.video-block__player__button:focus, .video-block__player__button:hover {
  background: #00396e;
}
#video-block__pause {
  display: none;
}
@media only screen and (max-width: 768px) {
  .video-block {
    margin-top: 6rem;
    display: flex;
    flex-direction: column;
    padding-left: 0;
    height: 100%;
  }

  #video-block__block {
    display: none;
    width: 100%;
    height: 10rem;
    left: 0;
    order: 2;
    padding-left: 0;
    padding-bottom: 0;
    z-index: -2;
  }

  .video-block__player {
    border-radius: 0;
    width: 100%;
    height: 100%;
    position: relative;
    order: 1;
  }

  .video-block__title {
    max-width: 35rem;
    width: 100%;
    left: unset;
    top: unset;
    position: relative;
    order: 2;
    padding: 2rem;
  }
  .video-block__title::after {
    top: unset;
    bottom: 0;
    left: 10%;
  }
}
.header-wrapper {
  display: flex;
  flex-direction: column;
  background-color: transparent;
}
header {
  background-color: transparent;
  width: 100%;
}
.info-bar {
  display: flex;
  flex-direction: row;
  padding: 1rem 2rem;
  width: 100%;
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
}
.info-bar__wrapper {
  width: 100%;
  background-color: #F0F2F5;
}
.header__phone, .header__mail {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.header__phone {
  padding-right: 2rem;
}
.info-bar a {
  color: #496783;
  font-family: "PT Sans", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0;
  text-transform: none;
  color: #203141;
  margin: 0;
  padding-left: 0.5rem;
}
.info-bar a:hover {
  text-underline-offset: 0.3em;
  text-decoration: underline;
}
.info-bar img {
  width: 1rem;
  height: 1rem;
  filter: invert(38%) sepia(17%) saturate(1010%) hue-rotate(168deg) brightness(94%) contrast(87%);
}
@media only screen and (max-width: 600px) {
  .info-bar {
    display: none;
  }
}
.menu-wrapper {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding: 3rem 2rem;
  background-color: transparent;
  max-width: 1400px;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
}
.logo {
  width: 15rem;
}
.menu-list > li:hover > .submenu {
  display: flex;
}
.submenu:hover {
  display: flex;
}
.submenu {
  display: none;
  position: absolute;
  top: 3rem;
  left: 1rem;
  width: 13rem;
  background-color: #ffffff;
  padding: 1rem;
  z-index: 667;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05), 0 1px 2px 0 rgba(0, 3, 5, 0.2);
  border-radius: 3px;
}
li:last-child > .submenu {
  top: 5rem;
  left: unset;
  right: 0rem;
  align-items: flex-end;
}
.submenu > a {
  text-decoration: none;
  color: #8FA9C2;
  font-size: 1rem;
}
.submenu > a:hover, .submenu .current_subpage_item {
  text-decoration: underline;
  text-underline-offset: 0.3em;
  text-decoration-thickness: 2px;
}
.menu-list {
  display: flex;
  justify-content: space-evenly;
  text-align: center;
  align-items: center;
  flex-direction: row;
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.menu-list > li {
  position: relative;
  width: -moz-fit-content;
  width: fit-content;
  padding: 1rem 1rem;
}
.menu-list > li > a {
  font-family: "PT Sans", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0;
  text-transform: none;
  color: #203141;
  margin-left: auto;
  margin-right: auto;
}
.menu-list > li > a:link, .menu-list > li > a:visited {
  text-decoration: none;
  color: #8FA9C2;
}
.menu-list > li:not(:last-child) > a:hover {
  border-bottom: 2.5px solid #004B92;
}
li:not(:last-child) .current_page_item {
  border-bottom: 2.5px solid #004B92;
}
.menu-list > li > .current_page_item {
  color: #203141 !important;
  font-family: "PT Sans", sans-serif;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0;
  text-transform: none;
  color: #203141;
}
.mobile-nav__element {
  display: none;
}
/**
 * For wide screens the last menu item appears as a button
 */
@media (min-width: 1024px) {
  .menu-list > li:last-child > a {
    background-color: #004B92;
    border-radius: 30.38px;
    padding: 1rem;
    box-shadow: 0 10px 15px 0 rgba(1, 3, 4, 0.05), 0 1px 2px 0 rgba(1, 3, 4, 0.2), inset 0 1px 0 0 #0057A9;
    display: inline-block;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    cursor: pointer;
    font-family: "PT Sans", sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0;
    text-transform: none;
    color: #203141;
    width: -moz-fit-content;
    width: fit-content;
    position: relative;
    transition: background-color 0.6s ease;
    overflow: hidden;
    color: white;
    align-items: center;
    display: flex;
    text-align: center;
  }
  .menu-list > li:last-child > a:after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    top: 50%;
    left: 50%;
    transform-style: flat;
    transform: translate3d(-50%, -50%, 0);
    background: rgba(255, 255, 255, 0.2);
    border-radius: 100%;
    transition: width 0.3s ease, height 0.3s ease;
  }
  .menu-list > li:last-child > a:focus, .menu-list > li:last-child > a:hover {
    background: #00396e;
  }
  .menu-list > li:last-child > a:active:after {
    width: 0;
    height: 0;
  }

  .menu-list > li:last-child {
    padding-right: 0;
  }

  .menu-list > .current_page_item:last-child {
    border-bottom: 0px;
  }

  .menu-list > li:last-child > .current_page_item {
    color: white !important;
  }
}
@media only screen and (max-width: 1024px) {
  .logo {
    width: 10rem;
  }
}
@media only screen and (max-width: 1024px) {
  main {
    margin-top: 6rem;
  }

  .submenu {
    position: relative;
    display: flex;
    top: unset;
    background: unset;
    box-shadow: unset;
    align-items: flex-end;
    width: 100%;
    padding-top: 0.5rem;
    padding-bottom: 0;
  }

  .menu-list {
    height: 70vh;
    width: 35vw;
    overflow-y: auto;
    gap: 1rem;
    text-align: end;
    flex-direction: column;
    justify-content: flex-start;
    padding-right: 1rem;
    padding-right: 7vw;
  }

  .menu {
    position: absolute;
    width: 60vw;
    height: 110vh;
    margin: -50px -50px 0 -40vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FCC300;
    -webkit-font-smoothing: antialiased;
    transform-origin: 0% 0%;
    transform: translate(100%, 0);
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
  }

  .menu-list li {
    width: 100%;
    transition-delay: 2s;
  }

  li:last-child > .submenu {
    top: unset;
    left: unset;
    right: unset;
    align-items: flex-end;
    padding-right: 0;
  }

  .logo__link {
    margin-bottom: 0;
    line-height: 0;
  }

  .menu-list > li {
    padding: 0;
  }

  .menu-wrapper {
    position: fixed;
    top: 0;
    background-color: white;
    z-index: 667;
    padding: 1.5rem 2rem;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  }

  .mobile-nav__element {
    display: block;
  }

  .menu-list > li > a {
    text-decoration: none;
    transition: 200ms;
    opacity: 1;
    font-family: "PT Sans Caption", sans-serif;
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 2rem;
    letter-spacing: 0;
    text-transform: none;
    color: #203141;
    color: #496783;
  }

  .submenu > a {
    color: #496783;
  }

  .menu-list > li > a:link, .menu-list > li > a:visited {
    text-decoration: none;
    color: #496783;
  }

  .current_page_item {
    border-bottom: 2.5px solid #004B92;
  }

  .menu-list > li > .current_page_item {
    color: #203141 !important;
    font-family: "PT Sans Caption", sans-serif;
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 2rem;
    letter-spacing: 0;
    text-transform: none;
    color: #203141;
    color: black;
  }

  ul {
    padding: 0;
    list-style-type: none;
  }

  nav {
    height: 3rem;
    width: 3rem;
  }

  #menuToggle {
    padding-top: 1rem;
    display: flex;
    flex-direction: column;
    position: relative;
    top: 0;
    left: 0;
    z-index: 1;
    -webkit-user-select: none;
    user-select: none;
  }

  #menuToggle input {
    display: flex;
    width: 40px;
    height: 32px;
    position: absolute;
    bottom: -1rem;
    cursor: pointer;
    opacity: 0;
    z-index: 2;
  }

  #menuToggle span {
    display: flex;
    width: 29px;
    height: 2px;
    margin-bottom: 5px;
    margin-left: 0.5rem;
    position: relative;
    background: #203141;
    border-radius: 3px;
    z-index: 1;
    transform-origin: 5px 0px;
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
  }

  #menuToggle span:first-child {
    transform-origin: 0% 0%;
  }

  #menuToggle span:nth-last-child(2) {
    transform-origin: 0% 100%;
  }

  input:checked ~ .menu {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.35);
  }

  #menuToggle input:checked ~ span {
    opacity: 1;
    transform: rotate(45deg) translate(-3px, -1px);
    background: #36383F;
  }

  #menuToggle input:checked ~ span:nth-last-child(3) {
    opacity: 0;
    transform: rotate(0deg) scale(0.2, 0.2);
  }

  #menuToggle input:checked ~ span:nth-last-child(2) {
    transform: rotate(-45deg) translate(0, -1px);
  }

  #menuToggle input:checked ~ ul {
    transform: none;
  }
}
@media only screen and (max-width: 768px) {
  main {
    margin-top: 2.5rem;
  }
}
@media (max-width: 551px) {
  .menu {
    width: 80vw;
    margin: -50px -50px 0 -60vw;
  }

  .menu-list {
    width: 50vw;
    padding-right: 6vw;
  }
}
@media (max-width: 400px) {
  .menu {
    width: 90vw;
    margin: -50px -50px 0 -65vw;
  }
}
@media (max-width: 365px) {
  .menu {
    width: 90vw;
  }

  .menu-list {
    width: 80vw;
    padding-right: 20vw;
  }
}
.offerings__wrapper {
  background-color: #FCC300;
}
.offerings > * {
	/* added by fgp */
	--parent-has-fgp: !important;
	--element-has-fgp: initial;
	pointer-events: var(--parent-has-fgp) auto;
	--fgp-parent-gap-row: 1rem;
	--fgp-margin-top: var(--parent-has-fgp) calc(var(--fgp-gap-row) + var(--orig-margin-top, 0px));
	margin-top: var(--fgp-margin-top);
	--fgp-parent-gap-column: 1rem;
	--fgp-margin-left: var(--parent-has-fgp) calc(var(--fgp-gap-column) + var(--orig-margin-left, 0px));
	margin-left: var(--fgp-margin-left);
}
.offerings > * > * {
	/* added by fgp */
	--parent-has-fgp: initial;
	--fgp-parent-gap-row: initial;
	--fgp-parent-gap-column: initial;
}
.offerings {
	/* added by fgp */
	--has-fgp: ;
	--element-has-fgp: ;
	pointer-events: var(--has-fgp) none;
	--fgp-gap-row: 1rem;
	--fgp-margin-top: var(--has-fgp) calc(var(--fgp-parent-gap-row, 0px) / (1 + var(--fgp--parent-gap-as-decimal, 0)) - var(--fgp-gap-row) + var(--orig-margin-top, 0px)) !important;
	--fgp-gap-column: 1rem;
	--fgp-margin-left: var(--has-fgp) calc(var(--fgp-parent-gap-column, 0px) / (1 + var(--fgp--parent-gap-as-decimal, 0)) - var(--fgp-gap-column) + var(--orig-margin-left, 0px)) !important;
}
.offerings {
	display: flex;
	flex-direction: column;
	--fgp-gap: var(--has-fgp, 1rem);
	gap: var(--fgp-gap, 0px);
	margin-top: var(--fgp-margin-top, var(--orig-margin-top));
	margin-left: var(--fgp-margin-left, var(--orig-margin-left));
}
.offerings__outside {
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
  padding: 2rem 2rem;
}
.offerings > .card-small {
  max-width: 51%;
  width: 100%;
}
@media only screen and (max-width: 600px) {
  .offerings > .card-small {
    max-width: 100%;
    width: 100%;
  }
}
.consent-banner {
  visibility: hidden;
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 1000;
  max-width: 30rem;
  margin: 1rem;
  padding: 2rem;
  border-radius: 5px;
  box-shadow: 0px 0px 100px rgba(0, 0, 0, 0.5);
  overflow: hidden;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: white;
}
.consent-banner__headline {
  color: #203141;
  -webkit-hyphens: auto;
          hyphens: auto;
}
.consent-banner__accept {
  background-color: #004B92;
  border-radius: 30.38px;
  padding: 1rem;
  box-shadow: 0 10px 15px 0 rgba(1, 3, 4, 0.05), 0 1px 2px 0 rgba(1, 3, 4, 0.2), inset 0 1px 0 0 #0057A9;
  display: inline-block;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  cursor: pointer;
  font-family: "PT Sans", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0;
  text-transform: none;
  color: #203141;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
  transition: background-color 0.6s ease;
  overflow: hidden;
  color: white;
  margin-right: 1.5rem;
  border: none;
}
.consent-banner__accept:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  top: 50%;
  left: 50%;
  transform-style: flat;
  transform: translate3d(-50%, -50%, 0);
  background: rgba(255, 255, 255, 0.2);
  border-radius: 100%;
  transition: width 0.3s ease, height 0.3s ease;
}
.consent-banner__accept:focus, .consent-banner__accept:hover {
  background: #00396e;
}
.consent-banner__accept:active:after {
  width: 0;
  height: 0;
}
.consent-banner__decline {
  padding: 0;
  border: none;
  text-decoration: none;
  color: inherit;
  background: none;
  cursor: pointer;
  text-decoration: underline;
  text-underline-offset: 0.333em;
}
.dsg-settings {
  cursor: pointer;
}
@media only screen and (max-width: 600px) {
  .consent-banner {
    top: 5rem;
    bottom: unset;
    right: unset;
    left: 0;
    max-width: calc(100% - 2rem);
    padding: 1rem;
  }
}
.about {
  display: flex;
  flex-direction: row-reverse;
  padding: 1rem 2rem 2rem 2rem;
  z-index: 2;
  max-width: calc( 1400px );
  margin-left: auto;
  margin-right: auto;
}
.about__box {
  position: relative;
  background-color: #F0F2F5;
  width: 24rem;
  border-radius: 3px;
  padding-left: 2rem;
  padding-right: 3rem;
  padding-top: 2em;
  padding-bottom: 1rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05), 0 1px 2px 0 rgba(0, 3, 5, 0.2);
}
.about__box::after {
  content: "";
  position: absolute;
  background-color: #FCC300;
  z-index: 5;
  top: -0.175rem;
  left: 2rem;
  width: 15rem;
  height: 0.35rem;
  position: absolute !important;
}
.landing__backdrop {
  mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.95) 90%);
  -webkit-mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.95) 100%);
  top: 0;
  height: 38.5rem;
  width: 100%;
  position: absolute;
  z-index: -1;
  -o-object-fit: cover;
     object-fit: cover;
}
@media only screen and (max-width: 450px) {
  .landing__backdrop {
    mask-image: unset;
    -webkit-mask-image: unset;
    top: 0;
    height: 38.5rem;
    width: 100%;
    position: absolute;
    z-index: 2;
    -o-object-fit: cover;
       object-fit: cover;
  }

  .about {
    height: 45rem;
    background-color: #FCC300;
    z-index: -2;
  }

  .about__box {
    z-index: 3;
    margin-top: 90%;
    height: -moz-fit-content;
    height: fit-content;
  }
}
@media only screen and (max-width: 390px) {
  .about__box::after {
    width: 30vw;
  }
}
.about__box__header {
  font-family: "PT Sans Caption", sans-serif;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 2rem;
  letter-spacing: 0;
  text-transform: none;
  color: #203141;
  line-height: 2rem;
}
.about__box__button {
  margin-top: 1rem;
  background-color: #FFFFFF;
  color: #203141;
  border-radius: 30.38px;
  padding: 15px;
  box-shadow: 0 1px 2px 0 rgba(1, 3, 4, 0.15), 0 10px 15px 0 rgba(1, 3, 4, 0.05), inset 0 1px 0 0 #F5F9FC;
  display: inline-block;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  cursor: pointer;
  font-family: "PT Sans", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0;
  text-transform: none;
  color: #203141;
  width: -moz-fit-content;
  width: fit-content;
  overflow: hidden;
  position: relative;
  transition: background-color 0.6s ease;
}
.about__box__button:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  top: 50%;
  left: 50%;
  transform-style: flat;
  transform: translate3d(-50%, -50%, 0);
  background: rgba(255, 255, 255, 0.2);
  border-radius: 100%;
  transition: width 0.3s ease, height 0.3s ease;
}
.about__box__button:focus, .about__box__button:hover {
  background: #f2f2f2;
}
.about__box__button:active:after {
  width: 300px;
  height: 300px;
}
.about__box__content {
  font-family: "PT Sans", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0;
  text-transform: none;
  color: #203141;
}
.career-intro__wrapper {
  display: flex;
  flex-direction: row;
}
.career__wrapper {
  background-color: #FCC300;
  width: 50%;
  padding: 3rem 2rem 3rem 2rem;
}
.career {
  max-width: 500px;
  margin-left: auto;
  margin-right: 7rem;
  height: inherit;
}
.career__button {
  margin-top: 2rem;
  background-color: #FFFFFF;
  color: #203141;
  border-radius: 30.38px;
  padding: 15px;
  box-shadow: 0 1px 2px 0 rgba(1, 3, 4, 0.15), 0 10px 15px 0 rgba(1, 3, 4, 0.05), inset 0 1px 0 0 #F5F9FC;
  display: inline-block;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  cursor: pointer;
  font-family: "PT Sans", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0;
  text-transform: none;
  color: #203141;
  width: -moz-fit-content;
  width: fit-content;
  overflow: hidden;
  position: relative;
  transition: background-color 0.6s ease;
}
.career__button:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  top: 50%;
  left: 50%;
  transform-style: flat;
  transform: translate3d(-50%, -50%, 0);
  background: rgba(255, 255, 255, 0.2);
  border-radius: 100%;
  transition: width 0.3s ease, height 0.3s ease;
}
.career__button:focus, .career__button:hover {
  background: #f2f2f2;
}
.career__button:active:after {
  width: 300px;
  height: 300px;
}
.career__header {
  font-family: "PT Sans Caption", sans-serif;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 2rem;
  letter-spacing: 0;
  text-transform: none;
  color: #203141;
}
.career__content, .career__header {
  color: #001A33 !important;
}
@media only screen and (max-width: 1400px) {
  .career {
    margin-right: auto;
  }

  .career .card-small {
    max-width: 100%;
  }
}
@media only screen and (max-width: 600px) {
  .career-intro__wrapper {
    flex-direction: column;
  }

  .career__wrapper {
    width: 100%;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .career__button {
    margin-top: 1rem;
  }
}
.open-positions {
  max-width: 500px;
  margin-right: auto;
  margin-left: 7rem;
  height: inherit;
}
.open-positions__wrapper {
  background-color: #F9E08A;
  height: inherit;
  width: 50%;
  padding: 3rem 2rem 3rem 2rem;
}
.open-positions__header {
  width: 50%;
  font-family: "PT Sans Caption", sans-serif;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 2rem;
  letter-spacing: 0;
  text-transform: none;
  color: #203141;
  color: #001A33 !important;
}
.open-positions__link:link, .open-positions__link:visited {
  color: #203141 !important;
  text-decoration: none;
  text-decoration: underline;
  text-underline-offset: 0.1em;
}
@media only screen and (max-width: 1400px) {
  .open-positions {
    margin-left: auto;
  }

  .open-positions .card-small {
    max-width: 100%;
  }
}
@media only screen and (max-width: 600px) {
  .open-positions__wrapper {
    width: 100%;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}
.illustration {
  position: relative;
  width: 100%;
  margin-bottom: 0rem;
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
}
.illustration__backdrop__grey {
  position: absolute;
  z-index: -2;
  margin-left: -100%;
  bottom: 0;
  width: 200vw;
  height: 7.75rem;
  background-color: #EAECEF;
}
.illustration__backdrop__dark {
  position: absolute;
  z-index: -2;
  margin-left: -100%;
  bottom: 7.5rem;
  width: 200vw;
  height: 0.15rem;
  background-color: #1A3140;
}
.kfm-illustration {
  width: 100%;
  z-index: -1;
  left: 0;
  margin-top: 10rem;
}
.illustration__text {
  position: absolute;
  top: 0;
  left: 0;
  width: 40%;
  max-width: 25rem;
  margin: 5rem 2rem;
  margin-bottom: 0;
}
.illustration__text__header {
  font-family: "PT Sans Caption", sans-serif;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 2rem;
  letter-spacing: 0;
  text-transform: none;
  color: #203141;
  color: #001A33;
}
.illustration__text__content {
  font-family: "PT Sans", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0;
  text-transform: none;
  color: #203141;
  color: #001A33;
}
@media only screen and (max-width: 1400px) {
  .illustration__backdrop__dark, .illustration__backdrop__grey {
    display: none;
  }
}
@media only screen and (max-width: 1200px) {
  .kfm-illustration {
    margin-top: 15rem;
  }
}
@media only screen and (max-width: 900px) {
  .kfm-illustration {
    margin-top: 20rem;
  }
}
@media only screen and (max-width: 768px) {
  .kfm-illustration {
    width: 125%;
    height: auto;
    margin-left: -25%;
    margin-top: 23rem;
  }

  .illustration__text {
    width: 70%;
  }
}
@media only screen and (max-width: 600px) {
  .kfm-illustration {
    margin-top: 20rem;
  }

  .illustration__text {
    width: 100%;
    max-width: 100%;
    padding: 3rem 12vw 3rem 8vw;
    margin: 0;
  }
}
@media only screen and (max-width: 450px) {
  .kfm-illustration {
    margin-top: 25rem;
  }
}
@media only screen and (max-width: 350px) {
  .kfm-illustration {
    margin-top: 30rem;
  }
}
.illustration__button {
  width: 2rem;
  height: 2rem;
  background-clip: padding-box;
  border-radius: 100%;
  border: 1rem solid rgba(252, 195, 0, 0.2);
  background-color: #FCC300;
  padding: 0.75rem;
  transition: all ease 0.6s;
  cursor: pointer;
  position: absolute;
}
.illustration__button:hover {
  border: 1rem solid rgba(252, 195, 0, 0.6);
  background-color: white;
}
.illustration__button:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  top: 50%;
  left: 50%;
  transform-style: flat;
  transform: translate3d(-50%, -50%, 0);
  background: rgba(255, 255, 255, 0.2);
  border-radius: 100%;
  transition: width 0.3s ease, height 0.3s ease;
}
.illustration__button:active:after {
  width: 100px;
  height: 100px;
}
#elektrotechnik__link {
  width: auto;
  height: auto;
  position: absolute;
  top: 73%;
  left: 36%;
}
#leitungsbau__link {
  position: absolute;
  top: 76%;
  left: 74%;
}
#breitband__link {
  position: absolute;
  top: 63%;
  left: 61.5%;
}
#fernmeldetechnik__link {
  position: absolute;
  top: 67%;
  left: 54.5%;
}
#endstellenbau__link {
  position: absolute;
  top: 65%;
  left: 91%;
}
@media only screen and (max-width: 1200px) {
  #elektrotechnik__link {
    top: 78%;
    left: 35%;
  }

  #leitungsbau__link {
    top: 79%;
    left: 73%;
  }

  #breitband__link {
    top: 69%;
    left: 61%;
  }

  #fernmeldetechnik__link {
    top: 72%;
    left: 54%;
  }

  #endstellenbau__link {
    top: 69%;
    left: 90.5%;
  }
}
@media only screen and (max-width: 900px) {
  #elektrotechnik__link {
    top: 82%;
    left: 35%;
  }

  #leitungsbau__link {
    top: 83%;
    left: 73%;
  }

  #breitband__link {
    top: 74%;
    left: 61%;
  }

  #fernmeldetechnik__link {
    top: 76%;
    left: 54%;
  }

  #endstellenbau__link {
    top: 7%;
    left: 90.5%;
  }
}
@media only screen and (max-width: 768px) {
  #elektrotechnik__link {
    top: 82%;
    left: 19%;
  }

  #leitungsbau__link {
    top: 84%;
    left: 65%;
  }

  #breitband__link {
    top: 75%;
    left: 51%;
  }

  #fernmeldetechnik__link {
    top: 78%;
    left: 42%;
  }

  #endstellenbau__link {
    top: 76%;
    left: 89%;
  }
}
@media only screen and (max-width: 600px) {
  #elektrotechnik__link {
    top: 83%;
    left: 17%;
  }

  #leitungsbau__link {
    top: 84%;
    left: 63%;
  }

  #breitband__link {
    top: 76%;
    left: 50%;
  }

  #fernmeldetechnik__link {
    top: 73%;
    left: 35%;
  }

  #endstellenbau__link {
    top: 75%;
    left: 84%;
  }
}
@media only screen and (max-width: 450px) {
  #elektrotechnik__link {
    top: 87%;
    left: 17%;
  }

  #leitungsbau__link {
    top: 88%;
    left: 63%;
  }

  #breitband__link {
    top: 81%;
    left: 48.5%;
  }

  #fernmeldetechnik__link {
    top: 77%;
    left: 33%;
  }

  #endstellenbau__link {
    top: 80%;
    left: 86%;
  }
}
@media only screen and (max-width: 350px) {
  #elektrotechnik__link {
    top: 90%;
    left: 17%;
  }

  #leitungsbau__link {
    top: 92%;
    left: 63%;
  }

  #breitband__link {
    top: 85%;
    left: 48.5%;
  }

  #fernmeldetechnik__link {
    top: 83%;
    left: 31%;
  }

  #endstellenbau__link {
    top: 85%;
    left: 83%;
  }
}
.equipment {
  background-color: #F0F2F5;
}
.equipment__wrapper {
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  padding: 5rem 2rem 3rem 2rem;
}
.equipment__content > * {
	/* added by fgp */
	--parent-has-fgp: !important;
	--element-has-fgp: initial;
	pointer-events: var(--parent-has-fgp) auto;
	--fgp-parent-gap-row: 2rem;
	--fgp-margin-top: var(--parent-has-fgp) calc(var(--fgp-gap-row) + var(--orig-margin-top, 0px));
	margin-top: var(--fgp-margin-top);
	--fgp-parent-gap-column: 2rem;
	--fgp-margin-left: var(--parent-has-fgp) calc(var(--fgp-gap-column) + var(--orig-margin-left, 0px));
	margin-left: var(--fgp-margin-left);
}
.equipment__content > * > * {
	/* added by fgp */
	--parent-has-fgp: initial;
	--fgp-parent-gap-row: initial;
	--fgp-parent-gap-column: initial;
}
.equipment__content {
	/* added by fgp */
	--has-fgp: ;
	--element-has-fgp: ;
	pointer-events: var(--has-fgp) none;
	--fgp-gap-row: 2rem;
	--fgp-margin-top: var(--has-fgp) calc(var(--fgp-parent-gap-row, 0px) / (1 + var(--fgp--parent-gap-as-decimal, 0)) - var(--fgp-gap-row) + var(--orig-margin-top, 0px)) !important;
	--fgp-gap-column: 2rem;
	--fgp-margin-left: var(--has-fgp) calc(var(--fgp-parent-gap-column, 0px) / (1 + var(--fgp--parent-gap-as-decimal, 0)) - var(--fgp-gap-column) + var(--orig-margin-left, 0px)) !important;
}
.equipment__content {
	display: flex;
	flex-direction: row;
	--fgp-gap: var(--has-fgp, 2rem);
	gap: var(--fgp-gap, 0px);
	justify-content: space-between;
	margin-top: var(--fgp-margin-top, var(--orig-margin-top));
	margin-left: var(--fgp-margin-left, var(--orig-margin-left));
}
.equipment__img__left {
  max-width: 20rem;
  -o-object-fit: contain;
     object-fit: contain;
  margin-left: calc(-2rem * 2);
}
.equipment__img__right {
  max-width: 20rem;
  transform: scaleX(-1);
  -o-object-fit: contain;
     object-fit: contain;
  margin-right: calc(-2rem * 2);
}
.equipment__text {
  width: 33%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-family: "PT Sans", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0;
  text-transform: none;
  color: #203141;
}
.equipment__text__content {
  max-width: 22rem;
}
.equipment__text__topic {
  font-family: "PT Sans", sans-serif;
  font-weight: 600;
  font-size: 0.8333rem;
  line-height: 1.5rem;
  letter-spacing: 0;
  text-transform: none;
  color: #203141;
  text-transform: uppercase !important;
  margin-bottom: 0.25rem;
  letter-spacing: 0.1em;
}
.equipment__text__header {
  font-family: "PT Sans Caption", sans-serif;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 2rem;
  letter-spacing: 0;
  text-transform: none;
  color: #203141;
}
.equipment__text__button {
  background-color: #FFFFFF;
  color: #203141;
  border-radius: 30.38px;
  padding: 15px;
  box-shadow: 0 1px 2px 0 rgba(1, 3, 4, 0.15), 0 10px 15px 0 rgba(1, 3, 4, 0.05), inset 0 1px 0 0 #F5F9FC;
  display: inline-block;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  cursor: pointer;
  font-family: "PT Sans", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0;
  text-transform: none;
  color: #203141;
  width: -moz-fit-content;
  width: fit-content;
  overflow: hidden;
  position: relative;
  transition: background-color 0.6s ease;
  margin-top: 2rem;
}
.equipment__text__button:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  top: 50%;
  left: 50%;
  transform-style: flat;
  transform: translate3d(-50%, -50%, 0);
  background: rgba(255, 255, 255, 0.2);
  border-radius: 100%;
  transition: width 0.3s ease, height 0.3s ease;
}
.equipment__text__button:focus, .equipment__text__button:hover {
  background: #f2f2f2;
}
.equipment__text__button:active:after {
  width: 300px;
  height: 300px;
}
@media only screen and (max-width: 768px) {
  .equipment__content {
    flex-direction: column;
    align-items: center;
  }

  .equipment__text {
    width: 100%;
  }

  .equipment__img__left, .equipment__img__right {
    position: relative !important;
    margin: 0;
  }
}
.bottom-nav {
  background-color: #001A33;
}
.bottom-nav__wrapper {
  padding: 2rem 2rem;
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.bottom-nav__flex-column {
  display: flex;
  flex-direction: column;
}
.bottom-nav__flex-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.logo-white {
  width: 10rem;
}
.bottom-nav__flex-column img {
  height: 1rem;
  filter: invert(14%) sepia(67%) saturate(344%) hue-rotate(167deg) brightness(99%) contrast(93%);
}
.bottom-nav__phone-button, .bottom-nav__mail-button {
  background-color: #FFFFFF;
  color: #203141;
  border-radius: 30.38px;
  padding: 15px;
  box-shadow: 0 1px 2px 0 rgba(1, 3, 4, 0.15), 0 10px 15px 0 rgba(1, 3, 4, 0.05), inset 0 1px 0 0 #F5F9FC;
  display: inline-block;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  cursor: pointer;
  font-family: "PT Sans", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0;
  text-transform: none;
  color: #203141;
  width: -moz-fit-content;
  width: fit-content;
  overflow: hidden;
  position: relative;
  transition: background-color 0.6s ease;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: fit-content;
  padding-left: 1rem;
  padding-right: 1rem;
  margin: 0;
}
.bottom-nav__phone-button:after, .bottom-nav__mail-button:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  top: 50%;
  left: 50%;
  transform-style: flat;
  transform: translate3d(-50%, -50%, 0);
  background: rgba(255, 255, 255, 0.2);
  border-radius: 100%;
  transition: width 0.3s ease, height 0.3s ease;
}
.bottom-nav__phone-button:focus, .bottom-nav__phone-button:hover, .bottom-nav__mail-button:focus, .bottom-nav__mail-button:hover {
  background: #f2f2f2;
}
.bottom-nav__phone-button:active:after, .bottom-nav__mail-button:active:after {
  width: 300px;
  height: 300px;
}
.bottom-nav__flex-column > a {
  margin-bottom: 0.5rem;
  color: white;
}
.bottom-nav__links__single {
  font-family: "PT Sans", sans-serif;
  font-weight: 400;
  font-size: 0.8333rem;
  line-height: 1.5rem;
  letter-spacing: 0;
  text-transform: none;
  color: #203141;
}
.bottom-nav__flex-column > a:visited, .bottom-nav__flex-column > a:link {
  text-decoration: none;
}
.bottom-nav__flex-column a:hover {
  text-underline-offset: 0.3em;
  text-decoration: underline;
}
.bottom-nav__phone-button p, .bottom-nav__mail-button p {
  padding-left: 1rem;
  margin: 0;
}
.bottom-nav__mail-button {
  margin-left: 1rem;
}
.bottom-nav__links {
  width: 60%;
}
.bottom-nav__links__header {
  color: white !important;
  font-family: "PT Sans", sans-serif;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0;
  text-transform: none;
  color: #203141;
}
#bottom-nav__left {
  width: 40%;
}
#bottom-nav__left > p {
  width: 45%;
  color: white !important;
  margin: 1rem 0 2rem 0;
  font-family: "PT Sans", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0;
  text-transform: none;
  color: #203141;
}
@media only screen and (max-width: 1024px) {
  #bottom-nav__left > p {
    width: 70%;
  }

  #bottom-nav__left > .flex-row-plain {
    flex-direction: column;
  }

  .bottom-nav__phone-button {
    margin-bottom: 1rem;
  }

  .bottom-nav__mail-button {
    margin-left: 0;
  }

  .bottom-nav__flex-row {
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
  }

  .bottom-nav__flex-row > * {
    margin-right: 8px;
  }
}
@media only screen and (max-width: 650px) {
  .bottom-nav__wrapper {
    width: 100%;
    flex-direction: column;
  }

  .bottom-nav__flex-row {
    justify-content: space-between;
    width: 100%;
  }

  .bottom-nav__links {
    width: 100%;
  }

  #bottom-nav__left {
    width: 100%;
    margin-bottom: 2rem;
  }

  #bottom-nav__left > p {
    width: 100%;
    max-width: 20rem;
    margin-top: 0;
  }

  #bottom-nav__left > .flex-row-plain {
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
  }

  .bottom-nav__phone-button {
    margin-bottom: 1rem;
    margin-right: 1rem;
  }

  .bottom-nav__mail-button {
    margin-bottom: 1rem;
  }
}
@media only screen and (max-width: 600px) {
  .bottom-nav__wrapper {
    padding: 2rem 2rem;
  }

  .bottom-nav__flex-row {
    flex-wrap: wrap;
  }
}
@media only screen and (max-width: 360px) {
  .bottom-nav__button__icon {
    display: none;
  }

  .bottom-nav__phone-button p, .bottom-nav__mail-button p {
    padding-left: 0rem;
  }
}
.info-footer {
  padding: 1rem 2rem;
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
}
.info-footer__inside > * {
	/* added by fgp */
	--parent-has-fgp: !important;
	--element-has-fgp: initial;
	pointer-events: var(--parent-has-fgp) auto;
	--fgp-parent-gap-row: 8px;
	--fgp-margin-top: var(--parent-has-fgp) calc(var(--fgp-gap-row) + var(--orig-margin-top, 0px));
	margin-top: var(--fgp-margin-top);
	--fgp-parent-gap-column: 8px;
	--fgp-margin-left: var(--parent-has-fgp) calc(var(--fgp-gap-column) + var(--orig-margin-left, 0px));
	margin-left: var(--fgp-margin-left);
}
.info-footer__inside > * > * {
	/* added by fgp */
	--parent-has-fgp: initial;
	--fgp-parent-gap-row: initial;
	--fgp-parent-gap-column: initial;
}
.info-footer__inside {
	/* added by fgp */
	--has-fgp: ;
	--element-has-fgp: ;
	pointer-events: var(--has-fgp) none;
	--fgp-gap-row: 8px;
	--fgp-margin-top: var(--has-fgp) calc(var(--fgp-parent-gap-row, 0px) / (1 + var(--fgp--parent-gap-as-decimal, 0)) - var(--fgp-gap-row) + var(--orig-margin-top, 0px)) !important;
	--fgp-gap-column: 8px;
	--fgp-margin-left: var(--has-fgp) calc(var(--fgp-parent-gap-column, 0px) / (1 + var(--fgp--parent-gap-as-decimal, 0)) - var(--fgp-gap-column) + var(--orig-margin-left, 0px)) !important;
}
.info-footer__inside {
	display: flex;
	--fgp-gap: var(--has-fgp, 8px);
	gap: var(--fgp-gap, 0px);
	justify-content: space-between;
	flex-direction: row;
	align-items: center;
	margin-top: var(--fgp-margin-top, var(--orig-margin-top));
	margin-left: var(--fgp-margin-left, var(--orig-margin-left));
}
.info-footer__links > * {
	/* added by fgp */
	--parent-has-fgp: !important;
	--element-has-fgp: initial;
	--orig-margin-top: initial;
	--orig-margin-right: initial;
	--orig-margin-bottom: initial;
	--orig-margin-left: initial;
	pointer-events: var(--parent-has-fgp) auto;
	--fgp-parent-gap-row: 1rem;
	--fgp-margin-top: var(--parent-has-fgp) calc(var(--fgp-gap-row) + var(--orig-margin-top, 0px));
	margin-top: var(--fgp-margin-top);
	--fgp-parent-gap-column: 1rem;
	--fgp-margin-left: var(--parent-has-fgp) calc(var(--fgp-gap-column) + var(--orig-margin-left, 0px));
	margin-left: var(--fgp-margin-left);
}
.info-footer__links > * > * {
	/* added by fgp */
	--parent-has-fgp: initial;
	--fgp-parent-gap-row: initial;
	--fgp-parent-gap-column: initial;
}
.info-footer__links {
	/* added by fgp */
	--has-fgp: ;
	--element-has-fgp: ;
	pointer-events: var(--has-fgp) none;
	--fgp-gap-row: 1rem;
	--fgp-margin-top: var(--has-fgp) calc(var(--fgp-parent-gap-row, 0px) / (1 + var(--fgp--parent-gap-as-decimal, 0)) - var(--fgp-gap-row) + var(--orig-margin-top, 0px)) !important;
	--fgp-gap-column: 1rem;
	--fgp-margin-left: var(--has-fgp) calc(var(--fgp-parent-gap-column, 0px) / (1 + var(--fgp--parent-gap-as-decimal, 0)) - var(--fgp-gap-column) + var(--orig-margin-left, 0px)) !important;
}
.info-footer__links {
	display: flex;
	justify-content: space-between;
	--fgp-gap: var(--has-fgp, 1rem);
	gap: var(--fgp-gap, 0px);
	padding: 0;
	--fgp-margin-top: initial;
	--fgp-margin-left: initial;
	--orig-margin-top: 0px;
	--orig-margin-right: 0px;
	--orig-margin-bottom: 0px;
	--orig-margin-left: 0px;
	margin: var(--fgp-margin-top, var(--orig-margin-top)) var(--orig-margin-right) var(--orig-margin-bottom) var(--fgp-margin-left, var(--orig-margin-left));
}
.info-footer__links > a {
  font-family: "PT Sans Caption", sans-serif;
  font-weight: 400;
  font-size: 0.777rem;
  line-height: 1rem;
  letter-spacing: 0rem;
  text-transform: none;
  color: #203141;
}
.info-footer__links > a:first-child {
  padding: 0;
}
.info-footer__links > a:visited, .info-footer__links > a:link, .info-footer__links > a {
  text-decoration: none;
  color: #496783;
  cursor: pointer;
}
.info-footer__links a:hover {
  text-underline-offset: 0.3em;
  text-decoration: underline;
}
.info-footer__inside > p {
  font-family: "PT Sans Caption", sans-serif;
  font-weight: 400;
  font-size: 0.777rem;
  line-height: 1rem;
  letter-spacing: 0rem;
  text-transform: none;
  color: #203141;
  color: #496783 !important;
  margin: 0;
}
@media only screen and (max-width: 768px) {
  .info-footer {
    padding: 1rem 2rem;
  }
}
@media only screen and (max-width: 600px) {
  .info-footer {
    padding: 1rem 2rem;
  }

  .info-footer__inside {
    flex-wrap: wrap;
    justify-content: center;
  }

  .info-footer__links {
    flex-wrap: wrap;
  }
}
/*# sourceMappingURL=style.css.map */
