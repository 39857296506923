.tooltip__text {
    @include buttonWhite;
    display: none;
    overflow: unset;
    z-index: 100;
}

.tooltip:hover>.tooltip__text  {
    display: block;
}

.tooltip>a:link, .tooltip>a:visited  {
    text-decoration: none;
}

.alignRight {
    margin-left: 5rem;
}

.alignLeft {
    margin-left: -12rem;
}

.tooltip__text::before {
    content: "";                                                            
    position: absolute;
    top: 0.6rem;
    width: 0;
    height: 0;
    transition: border-right .6s ease;
    border-top: 1rem solid transparent;
    border-bottom: 1rem solid transparent;
}

.tooltip__text:hover::before {
    border-bottom: 1rem solid transparent;
    border-top: 1rem solid transparent;
}

.alignRight::before {
    left: 0;
    margin-left: -1rem;
    border-right: 1.5rem solid white;
}

.alignLeft::before {
    right: 0;
    margin-right: -1rem;
    border-left: 1.5rem solid white;
}

.alignRight:hover::before {
    border-right-color: darken(white,5%);
}

.alignLeft:hover::before {
    border-left-color: darken(white,5%);
}