/* base */
@font-face {
    font-family: 'PT Sans', sans-serif;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 400;
    letter-spacing: 0;
    text-transform: none;
    src: url("./../fonts/PTSans-Regular.ttf");
}

/* alpha */
@font-face {
    font-family: "PT Sans Caption", sans-serif;
    font-size: 2.2rem;
    line-height: 1.25rem;
    font-weight: 600;
    letter-spacing: 0;
    text-transform: none;
    src: url("./../fonts/PTSansCaption-Bold.ttf");
}

/* beta */
@font-face {
    font-family: "PT Sans Caption", sans-serif;
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 600;
    letter-spacing: 0;
    text-transform: none;
    src: url("./../fonts/PTSans-Bold.ttf");
}

/* gamma */
@font-face {
    font-family: "PT Sans", sans-serif;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 600;
    letter-spacing: 0;
    text-transform: none;
    src: url("./../fonts/PTSans-Bold.ttf");
}

/* delta */
@font-face {
    font-family: "PT Sans", sans-serif;
    font-size: 0.8333rem;
    line-height: 1.5rem;
    font-weight: 400;
    letter-spacing: 0;
    text-transform: none;
    src: url("./../fonts/PTSans-Regular.ttf");
}

/* epsilon */
@font-face {
    font-family: "PT Sans", sans-serif;
    font-size: 0.8333rem;
    line-height: 1.5rem;
    font-weight: 600;
    letter-spacing: 0;
    text-transform: none;
    src: url("./../fonts/PTSans-Bold.ttf");
}

/* zeta */
@font-face {
    font-family: "PT Sans Caption", sans-serif;
    font-size: 0.777rem;
    line-height: 1rem;
    font-weight: 400;
    letter-spacing: 0rem;
    text-transform: none;
    src: url("./../fonts/PTSansCaption-Bold.ttf");
}
