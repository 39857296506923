.lead-form .postalCode {
  width: 100%;
}

.lead-form .message {
  margin-bottom: 1rem;
}

.lead-form .town {
  width: 100%;
}

.lead-form .pronoun {
  padding-top: 1rem;
}

// move honeypot out of sight
.honeypot {
  position: absolute;
  left: -9999px;
}

.spinner {
	position: relative;
	overflow: hidden;
	display: inline-block;
	margin-right: 0.25rem;
	margin-bottom: -0.1rem;

	font-size: 0.1rem;
	text-indent: -9999em;

	border-top: 1.5em solid rgba(white, 0.2);
	border-right: 1.5em solid rgba(white, 0.2);
	border-bottom: 1.5em solid rgba(white, 0.2);
	border-left: 1.5em solid white;

	transform: translateZ(0);
	animation: load8 1.1s infinite linear;

	&, &::after {
		border-radius: 50%;
		width: 10em;
		height: 10em;
	}
}

.is-hidden {
  display: none;
}

@keyframes load8 {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
