.illustration {
    position: relative;
    width: 100%;
    margin-bottom: 0rem;
    //overflow: hidden;

    max-width: $maxWidth;
    margin-left: auto;
    margin-right: auto;
}

.illustration__backdrop__grey {
    position: absolute;
    z-index: -2;
    margin-left: -100%;
    bottom: 0;
    width: 200vw;
    height: 7.75rem;
    background-color: #EAECEF;
}

.illustration__backdrop__dark {
  position: absolute;
  z-index: -2;
  margin-left: -100%;
  bottom: 7.5rem;
  width: 200vw;
  height: 0.15rem;
  background-color: #1A3140;
}

.kfm-illustration {
    width: 100%;
    z-index: -1;
    left: 0;
    margin-top: 10rem;
}

.illustration__text {
    position: absolute;
    top: 0;
    left: 0;
    width: 40%;
    max-width: 25rem;
    margin: 5rem $sidesMobile;
    margin-bottom: 0;
}

.illustration__text__header {
    @include font-stack(beta);
    color: $blue-900;
}

.illustration__text__content {
    @include font-stack(base);
    color: $blue-900;
}

@media only screen and (max-width: 1400px) {
  .illustration__backdrop__dark, .illustration__backdrop__grey {
    display: none;
  }
}

@media only screen and (max-width: 1200px) {
  .kfm-illustration {
      margin-top: 15rem;
  }
}

@media only screen and (max-width: 900px) {
  .kfm-illustration {
      margin-top: 20rem;
  }
}


@media only screen and (max-width: 768px) {
    .kfm-illustration {
        width: 125%;
        height: auto;
        margin-left: -25%;
        margin-top: 23rem;
    }

    .illustration__text {
      width: 70%;


  }
}

@media only screen and (max-width: 600px) {

    .kfm-illustration {
      margin-top: 20rem;
    }

    .illustration__text {
        width: 100%;
        max-width: 100%;
        padding: 3rem 12vw 3rem 8vw;
        margin: 0;

    }
}

@media only screen and (max-width: 450px) {

  .kfm-illustration {
    margin-top: 25rem;
  }
}

@media only screen and (max-width: 350px) {

  .kfm-illustration {
    margin-top: 30rem;
  }
}
