.company__header {
  position: relative;
  height: 30rem;
  max-width: $maxWidth;
  margin-left: auto;
  margin-right: auto;
  padding-left: $sidesMobile;
}

.company__header__title {
  position: absolute;
  width: 25rem;
  top: 19rem;
  left: $sidesMobile;

  @include font-stack(alpha);
  color: $blue;
  z-index: 3;
  line-height: 3rem;

  &::after {
      content: "";
      position: absolute;
      background-color: $yellow;
      width: 60%;
      height: 8px;
      z-index: 4;

      margin-top: 1rem;
      left: 2rem;
      top: 100%;
  }
}

#company__header__block {

  border-radius: 3px;
  position: absolute;
  background-color: $blue-50;
  height: 0;
  width: 30%;
  padding-bottom: 30%;

  z-index: 2;
  bottom: 0%;
  left: 10%;

}

.company__header__image {
  border-radius: 3px;
  background-color: $blue;
  position: absolute;
  z-index: 1;
  height: 80%;
  width: 70%;
  object-fit: cover;

  right: 0;
}

.company__header__mobile-background {
display: none;
}

@media only screen and (max-width: 768px) {

.company__header {
	margin-top: 6rem;
	display: flex;
	flex-direction: column;
	padding-left: 0;
	height: 100%;
}

#company__header__block {
  display: none;
  width: 100%;
  height: 10rem;
  left: 0;
  order: 2;
  padding-left: 0;
  padding-bottom: 0;
}

.company__header__image {
  border-radius: 0;
  width: 100%;
  height: auto;
  position: relative;
  order: 1;
}

.company__header__title {
  max-width: 35rem;
  width: 100%;
  left: unset;
  top: unset;
  position: relative;
  order: 2;
  padding: $sidesMobile;

  &::after {
    top: unset;
    bottom: 0;
    left: 10%;
  }
}

.company__header__mobile-background {
  display: block;
  position: absolute;
  bottom: 0;
  height: 20rem;
  width: 100%;
  background-color: $blue-50;
  order: 4;
}
}
