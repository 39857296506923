.job-banner {
    max-width: $maxWidth;
    margin-left: auto;
    margin-right: auto;
    padding: 2rem $sidesMobile;
}

.job-banner__wrapper {
    width: 100vw;
    background-color: $yellow;

}

.job-banner__headline {
    @include font-stack(beta);
}

@media only screen and (max-width: 600px) {
    .job-banner {
        padding: 2rem $sidesMobile;
    }
}
