@mixin svg__filter__blue200 {
    filter: invert(72%) sepia(5%) saturate(1527%) hue-rotate(168deg) brightness(91%) contrast(90%);
}

@mixin svg__filter__blue300 {
    filter: invert(38%) sepia(17%) saturate(1010%) hue-rotate(168deg) brightness(94%) contrast(87%);
}

@mixin svg__filter__blue500 {
    filter: invert(14%) sepia(67%) saturate(344%) hue-rotate(167deg) brightness(99%) contrast(93%);
}

@mixin svg__filter__blue900 {
    filter: invert(8%) sepia(47%) saturate(3049%) hue-rotate(191deg) brightness(91%) contrast(102%);
}

@mixin svg__filter__white {
    filter: invert(100%) sepia(3%) saturate(235%) hue-rotate(344deg) brightness(115%) contrast(100%);
}

@mixin svg__filter__red200 {
    filter: invert(46%) sepia(20%) saturate(1788%) hue-rotate(313deg) brightness(105%) contrast(69%);
}