@mixin buttonBlue {
    background-color: $blue;
    border-radius: 30.38px;
    padding: 1rem;
    box-shadow: 0 10px 15px 0 rgba(1,3,4,0.05), 0 1px 2px 0 rgba(1,3,4,0.20), inset 0 1px 0 0 #0057A9;
    display: inline-block;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    cursor: pointer;
    @include font-stack(base);
    width: fit-content;

    position: relative;
    transition: background-color .6s ease;
    overflow: hidden;

    &:after {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        top: 50%;
        left: 50%;
        
        transform-style: flat;
        transform: translate3d(-50%,-50%,0);
        background: rgba(white,.2);
        border-radius: 100%;
        transition: width .3s ease, height .3s ease;
      }
      &:focus,
      &:hover {
          background: darken($blue,7%);
      }
      &:active {
        &:after {
          width: 0;
          height: 0;
        }
      }
}

@mixin buttonWhite {
    background-color: $white;
    color: $blue-500;
    border-radius: 30.38px;
    padding: 15px;
    box-shadow: 0 1px 2px 0 rgba(1,3,4,0.15), 0 10px 15px 0 rgba(1,3,4,0.05), inset 0 1px 0 0 #F5F9FC;
    display: inline-block;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    cursor: pointer; 
    @include font-stack(base);
    width: fit-content;
    overflow: hidden;

    position: relative;
    transition: background-color .6s ease;

    &:after {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        top: 50%;
        left: 50%;
        transform-style: flat;
        transform: translate3d(-50%,-50%,0);
        background: rgba(white,.2);
        border-radius: 100%;
        transition: width .3s ease, height .3s ease;
      }
    &:focus,
    &:hover {
        background: darken(white,5%);
    }
    &:active {
      &:after {
        width: 300px;
        height: 300px;
      }
    }

}

@mixin buttonLightBlue {
    background-color: $blue-100;
    border-radius: 30.38px;
    padding: 15px;
    box-shadow: 0 1px 2px 0 rgba(1,3,4,0.15), 0 10px 15px 0 rgba(1,3,4,0.05), inset 0 1px 0 0 #E9F0F6;    
    display: inline-block;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    cursor: pointer; 
    @include font-stack(base);
    width: fit-content;

    position: relative;
    transition: background-color .6s ease;
    overflow: hidden;

    &:after {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        top: 50%;
        left: 50%;
        transform-style: flat;
        transform: translate3d(-50%,-50%,0);
        background: rgba(white,.2);
        border-radius: 100%;
        transition: width .3s ease, height .3s ease;
      }
      &:focus,
      &:hover {
          background: darken($blue-100,7%);
      }
      &:active {
        &:after {
          width: 300px;
          height: 300px;
        }
      }
}

