.info-bar {
    display: flex;
    flex-direction: row;
    padding: 1rem $sidesMobile;
    width: 100%;
    max-width: $maxWidth;
    margin-left: auto;
    margin-right: auto;
    height: 100%;
}

.info-bar__wrapper {
    width: 100%;
    background-color: $blue-50;
}

.header__phone, .header__mail {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.header__phone {
    padding-right: 2rem;
}

.info-bar a{
    color: $blue-300;
    @include font-stack(base);
    margin: 0;
    padding-left: 0.5rem;
}

.info-bar a:hover {
  text-underline-offset: 0.3em;
  text-decoration: underline;
}

.info-bar img {
    width: 1rem;
    height: 1rem;
    @include svg__filter__blue300;
}

@media only screen and (max-width: 600px) {
  .info-bar {
    display: none;
  }
}

