* {
	box-sizing: border-box;
}

html {
	font-size: 16px;
	@media (min-width: 1024px) {
		font-size: 18px;
	}
	@include font-stack(base);
	// overflow-x: hidden;
	scroll-behavior: smooth;
}

body {
	margin: 0;
	position: inherit;
	overflow-x: hidden;
}

h1, p, ul {
	margin-top: 0;
}


a:visited, a:link {
	text-decoration: none;
}

a:-webkit-any-link {
	text-decoration: none;
}

.blocks {
	width: 100%;
}




