.privacy__wrapper {
  max-width: $maxWidth;
  margin-left: auto;
  margin-right: auto;
  padding: $sidesMobile ;
  width: 100vw;
}

.privacy__button {
  @include buttonBlue;
  color: white;
  border: none;
}
