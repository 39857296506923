.slideshow{
    background-color: $blue-500;
    position: relative;
    width: 100%;
    width: 100vw;
}

.slideshow__wrapper {
  max-width: $maxWidth;
  margin-left: auto;
  margin-right: auto;
  padding: 3rem 0;
  overflow: hidden;
}

.slideshow__content {
    width: 50%;
    margin: auto;
}

.slideshow__list {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    width: 100%;
}

.slideshow__image {
    max-width: 44rem;
    max-height: 30rem;
    filter: brightness(35%);
    object-fit: cover;
}

.slideshow__text {
    position: relative;
    @include font-stack(epsilon);
    width: 50%;
    color: white;

    &:after {
        position: absolute;
        content: '';
        width: 4rem;
        height: 4px;
        background-color: $yellow;
        left: 0;
        margin-top: 2rem;
    }
}

.slider__back, .slider__forward {
    position: absolute;
    z-index: 2;
    top: 40%;

    @include controlButton;
}

.slider__back {
    left: 20%;
}

.slider__forward {
    right: 20%;
}

.slider__icon {
    width: 1rem;
    height: auto;
    @include svg__filter__blue500;
}

@media only screen and (max-width: 1024px) {
    .slider__back {
        left: 10%;
    }

    .slider__forward {
        right: 10%;
    }

    .slideshow__wrapper {
      width: 100%;
    }

    .slideshow__content {
      width: 70%;
    }

    .slideshow__text {
      width: 70%;
    }
}

@media only screen and (max-width: 768px) {
    .slider__back {
        left: 2%;
    }

    .slider__forward {
        right: 2%;
    }
}

@media only screen and (max-width: 500px) {
    .slideshow__wrapper {
      padding: 1.5rem 0;
    }

    .slider__back, .slider__forward {
      top: 33%
    }
}


