.video-block {
	max-width: $maxWidth;
	margin-left: auto;
	margin-right: auto;
	width: 100%;
	position: relative;

	height: 30rem;
}

.video-block__title {
	position: absolute;
	width: 25rem;
	top: 19rem;
	left: $sidesMobile;

	@include font-stack(alpha);
	color: $blue;
	z-index: 3;
	line-height: 3rem;

	&::after {
		content: "";
		position: absolute;
		background-color: $yellow;
		width: 60%;
		height: 8px;
		z-index: 4;

		margin-top: 1rem;
		left: 2rem;
		top: 100%;
	}
}

#video-block__block {
	border-radius: 3px;
	position: absolute;
	background-color: $blue-50;
	height: 0;
	width: 30%;
	padding-bottom: 30%;

	z-index: 2;
	bottom: 0%;
	left: 10%;
}

.video-block__player {
	position: absolute;
	z-index: 1;
	height: 80%;
	width: 70%;
	object-fit: cover;

	right: 0;
}

video {
	display: block;
	height: auto;
	width: 100%;
	object-fit: cover;
	max-height: 100%;
}

.video__thumbnail {
	left: 0;
	top: 0;
	position: absolute;

	width: 100%;
	height: 100%;

	z-index: 2;
	object-fit: cover;
}

.video-block__player__button {
	z-index: 3;
	position: absolute;
	right: 1rem;
	bottom: 1rem;

	width: 3rem;
	height: 3rem;
	border-radius: 50%;
	padding: 1rem;

	background-color: $blue;
    transition: background-color .6s ease;

	display: flex;
	justify-content: center;
	align-items: center;

	img {
		z-index: 4;
		height: 100%;
		width: 100%;
		position: relative;
		filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(119deg) brightness(108%) contrast(103%);
	}

	&:focus,
	&:hover {
		background: darken($blue,7%);
	}
}

#video-block__pause {
	display: none;
}

@media only screen and (max-width: 768px) {

	.video-block {
		margin-top: 6rem;
		display: flex;
		flex-direction: column;
		padding-left: 0;
		height: 100%;
	}

	#video-block__block {
	  display: none;
	  width: 100%;
	  height: 10rem;
	  left: 0;
	  order: 2;
	  padding-left: 0;
	  padding-bottom: 0;
	  z-index: -2;
	}

	.video-block__player {
	  border-radius: 0;
	  width: 100%;
	  height: 100%;
	  position: relative;
	  order: 1;
	}

	.video-block__title {
	  max-width: 35rem;
	  width: 100%;
	  left: unset;
	  top: unset;
	  position: relative;
	  order: 2;
	  padding: $sidesMobile;

	  &::after {
		top: unset;
		bottom: 0;
		left: 10%;
	  }
	}
}


