.appetizer__wrapper {
    width: 100%;
    max-width: $maxWidth;
    margin-left: auto;
    margin-right: auto;

	h1 {
		margin-top: 4rem;
		margin-left: 2rem;
	}
}

.appetizer__wrapper__inside {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    position: relative;
    justify-content: center;
    align-items: center;
}

.appetizer, .appetizer__image {
    margin: 5rem $sidesMobile;
}

.appetizer {
    display: flex;
    flex-direction: column;
    max-width: 20rem;
}

.appetizer__text {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
}

.appetizer__topic {
    @include font-stack(epsilon);
    text-transform: uppercase !important;
    margin-bottom: 0.25rem;
    letter-spacing: 0.1em;
}

.appetizer__topic, .appetizer__headline, .appetizer__text {
    color: $blue-900;
}

.appetizer__headline {
    @include font-stack(beta);
    hyphens: auto;
}

.appetizer__button {
    margin-top: 0;
    @include buttonLightBlue;
}

.appetizer__image {
    position: relative;
    width: 21rem;
    height: 21rem;

    background-color: $yellow;
    border-radius: 50%;
}

.appetizer__image .contact-person__image {
  transform: scale(1);
}

.appetizer__image>img {
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;
    z-index: 4;

    border-radius: 50%;
    width: 20rem;
    height: auto;

    box-shadow: 0 1px 2px 0 rgba(1,3,4,.1),0 5px 10px 0 rgba(1,3,4,.05),inset 0 1px 0 0 #f5f9fc;
}

.appetizer__image__mask__left {
    position: absolute;
    z-index: 2;

    width: 50%;
    height: 100%;

    background-color: white;
}

.appetizer__image__mask__right {
    position: absolute;
    z-index: 2;
    right: 0;

    width: 50%;
    height: 100%;
    background-color: white;
}

.appetizer__image__mask__bottom {
    position: absolute;
    z-index: 2;
    bottom: 0;

    width: 100%;
    height: 50%;
    background-color: white;
}

.appetizer__image__mask__top {
    position: absolute;
    z-index: 2;

    width: 100%;
    height: 50%;
    background-color: white;
}

.appetizer__image__mask {
    position: absolute;
    bottom: 0.5rem;
    left: 0.5rem;
    z-index: 3;

    width: 20rem;
    height: 20rem;
    background-color: white;
    border-radius: 50%;
}

@media only screen and (max-width: 768px) {
    .appetizer__wrapper__inside {
        flex-direction: column;
    }

    .appetizer__image {
        width: 15rem;
        height: 15rem;

        order: 1;
        margin-bottom: 2rem;
        margin-top: 2rem;
    }

    .appetizer {
        order: 2;
        margin-top: 0;
        margin-bottom: 2rem;
        align-items: center;
        text-align: center;
    }

    .appetizer__image>img {
        width: 14rem;
        height: auto;
    }

    .appetizer__image__mask {
        width: 14rem;
        height: 14rem;
    }
}

@media only screen and (max-width:400px) {
    .appetizer {
      padding: 0 $sidesMobile;
    }
}
