.card-small {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.05), 0 1px 2px 0 rgba(0,3,5,0.20);
    border-radius: 3px;

    padding: 1.5rem;
    cursor: pointer;
    margin-bottom: 1rem;
    max-width: 30rem;

    transition: background-color .6s ease;
    overflow: hidden;
    position: relative;

    &:after {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        top: 50%;
        left: 50%;

        transform-style: flat;
        transform: translate3d(-50%,-50%,0);
        background: rgba($blue-50,.2);
        border-radius: 100%;
        transition: width .3s ease, height .3s ease;
      }
      &:focus,
      &:hover {
          background: darken(white,5%);
      }
      &:active {
        &:after {
          width: 300px;
          height: 300px;
        }
      }
}

.card-small__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.card-small__header {
    @include font-stack(gamma);
    color: $blue-500 !important;
    margin: 0;
}

.card-small__description {
    color: $blue-300 !important;
    margin: 0;
}

.card-small__icon {
    width: 1rem;
    @include svg__filter__blue900;
}

.card-small-plain {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.05), 0 1px 2px 0 rgba(0,3,5,0.20);
    border-radius: 3px;

    padding: 1.5rem;
    margin-bottom: 1rem;
    max-width: 30rem;

    overflow: hidden;
    position: relative;
}

