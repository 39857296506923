.radio {
    display: inline-flex;
    cursor: pointer;

    align-items: center;
    @include font-stack(base);
}

.radio__input {
    display: none;
}

.radio__radio {
    width: 1.75rem;
    height: 1.75rem;
    border: 5px solid $blue-50;
    background-color: $blue-50;
    border-radius: 50%;
    margin-right: 1rem;
    box-sizing: border-box;
    padding: 2px;

    &::after {
        content: '';
        width: 100%;
        height: 100%;
        display: block;
        background: $blue-300;
        border-radius: 50%;

        transform: scale(0);
        transition: transform 0.15s;
    }
}

.radio__input:checked + .radio__radio::after {
    transform: scale(1);
}

.radio__radio--error {
    background-color: $red-50;
    border: 5px solid $red-50;
}




