.offerings__wrapper {
  background-color: $yellow;
}

.offerings {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.offerings__outside {
  max-width: $maxWidth;
  margin-left: auto;
  margin-right: auto;
  padding: 2rem $sidesMobile;
}

.offerings > .card-small {
  max-width: 51%;
  width: 100%;
}

@media only screen and (max-width: 600px) {
  .offerings > .card-small {
    max-width: 100%;
    width: 100%;
  }
}
