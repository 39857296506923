@mixin font-stack($stack: base) {
	$font: map-get($fonts, $stack);

	font-family:map-get($font, family);
	font-weight:map-get($font, weight);

	font-size: map-get($font, size);
	line-height: map-get($font, line-height);

	letter-spacing:map-get($font, letter-spacing);
	text-transform:map-get($font, text-transform);
	color: $blue-500;
}
