.contact-form__wrapper {
    width: 100%;
    max-width: $maxWidth;
    margin-right: auto;
    margin-left: auto;
}

.contact-form {
    width: 60%;
    padding: 2rem $sidesMobile;
}

// move honeypot out of sight
.honeypot {
    position: absolute;
    left: -9999px;
}

.contact-form__field {
    border-bottom: 1px solid $blue-50;
    padding: 1rem 0;
    width: 100%;
    gap: 1rem;
}

.contact-form__field__description > h1{
    @include font-stack(beta);
    margin-bottom: 0.5rem;
}

.contact-form__field__description {
    width: 40%;
}

.contact-form__field__input {
    width: 60%;
}

.submit {
    @include buttonBlue;
    color: white;
    outline: none;
    border: 0px;
    margin-top: 1rem;
}

.town {
    width: 70%;
}

.postalCode {
    width: 30%;
}

.postal-town {
    gap: 1rem
}

.surname {
    width: 50%;
}

.name {
    width: 50%;
}

.name-surname {
    gap: 1rem;
    width: 100%;
}

.pronoun {
    width: 100%;
    gap: 2rem;
}

.alert--success {
    background-color: $green-100;
}

.alert--error {
    background-color: $red-100;
}

.alert {
    border-radius: 15px;
    padding: 0.5rem 1rem;
    width: fit-content;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    color: $blue-500;
}

.submit-wrapper {
    border-bottom: 0px;
}

@media only screen and (max-width: 1400px) {
    .contact-form {
        width: unset;
    }
}

@media only screen and (max-width: 768px) {

    .contact-form {
        width: 100vw;
    }

    .contact-form__field {
        flex-direction: column !important;
    }

    .contact-form__field__description {
        width: 100%;
    }

    .contact-form__field__input {
        width: 100%;
    }
}

@media only screen and (max-width: 600px) {
    .contact-form {
        padding: 2rem $sidesMobile;
    }

    .pronoun {
        gap: 1rem;
    }
}


