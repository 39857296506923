.equipment {
  background-color: $blue-50;
}

.equipment__wrapper {
  max-width: $maxWidth;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  padding: 5rem $sidesMobile 3rem $sidesMobile;
}

.equipment__content {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  justify-content: space-between;
}

.equipment__img__left {
  max-width: 20rem;
  object-fit: contain;
  margin-left: calc(-#{$sidesMobile} * 2);
}

.equipment__img__right {
  max-width: 20rem;
  transform: scaleX(-1);
  object-fit: contain;
  margin-right: calc(-#{$sidesMobile} * 2);
}

.equipment__text {
  width: 33%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  @include font-stack(base);
}

.equipment__text__content {
  max-width: 22rem;
}

.equipment__text__topic {
    @include font-stack(epsilon);
    text-transform: uppercase !important;
    margin-bottom: 0.25rem;
    letter-spacing: 0.1em;
}

.equipment__text__header {
  @include font-stack(beta);
}

.equipment__text__button {
  @include buttonWhite;
  margin-top: 2rem;
}

@media only screen and (max-width: 768px) {
  .equipment__content {
    flex-direction: column;
    align-items: center;
  }

  .equipment__text {
    width: 100%;
  }

  .equipment__img__left, .equipment__img__right {
    position: relative !important;
    margin: 0;
  }
}
