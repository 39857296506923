.image-with-text {
    padding: 2rem $sidesMobile;
    width: 100vw;
    max-width: $maxWidth;
    margin-right: auto;
    margin-left: auto;
}

.image-with-text__image {
    width: 60%;
}

.image-with-text__text {
    position: relative;
    @include font-stack(epsilon);
    width: 35%;

    &:after {
        position: absolute;
        content: '';
        width: 4rem;
        height: 4px;
        background-color: $yellow;
        left: 0;
        margin-top: 2rem;
    }
}

@media only screen and (max-width: 768px) {
    .image-with-text__text {
        width: 50%;
    }
}

@media only screen and (max-width: 600px) {
    .image-with-text {
        margin: 2rem $sidesMobile 3rem $sidesMobile;
        width: unset;
        padding: 0;
    }

    .image-with-text__image {
        width: 100%;
        text-align: center;
    }

    .image-with-text__text {
        width: 80%;
    }
}
