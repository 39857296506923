.info-footer {
    padding: 1rem $sidesMobile;
    max-width: $maxWidth;
    margin-left: auto;
    margin-right: auto;
}

.info-footer__inside {
  display: flex;
  gap: 8px;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
}

.info-footer__links {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    padding: 0;
    margin: 0;
}

.info-footer__links>a {
    @include font-stack(zeta)
}

.info-footer__links>a:first-child {
  padding: 0;
}

.info-footer__links>a:visited, .info-footer__links>a:link , .info-footer__links>a{
    text-decoration: none;
    color: $blue-300;
    cursor: pointer;
}

.info-footer__links a:hover {
  text-underline-offset: 0.3em;
  text-decoration: underline;
}

.info-footer__inside>p {
    @include font-stack(zeta);
    color: $blue-300 !important;
    margin: 0;

}

@media only screen and (max-width: 768px) {
    .info-footer {
        padding: 1rem $sidesMobile;
    }
}

@media only screen and (max-width: 600px) {
    .info-footer {
        padding: 1rem $sidesMobile;
    }

    .info-footer__inside {
        flex-wrap: wrap;
        justify-content: center;
    }

    .info-footer__links {
      flex-wrap: wrap;
    }
}
