.bottom-nav {
    background-color: $blue-900;
}

.bottom-nav__wrapper {
    padding: 2rem $sidesMobile;

    max-width: $maxWidth;
    margin-left: auto;
    margin-right: auto;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.bottom-nav__flex-column {
    display: flex;
    flex-direction: column;
}

.bottom-nav__flex-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.logo-white {
    width: 10rem;
}

.bottom-nav__flex-column img {
    height: 1rem;
    @include svg__filter__blue500;
}

.bottom-nav__phone-button, .bottom-nav__mail-button {
    @include buttonWhite;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: fit-content;
    padding-left: 1rem;
    padding-right: 1rem;
    margin: 0;
}

.bottom-nav__flex-column>a {
    margin-bottom: 0.5rem;
    color: white;
}

.bottom-nav__links__single {
    @include font-stack(delta);
}

.bottom-nav__flex-column>a:visited, .bottom-nav__flex-column>a:link {
    text-decoration: none;
}

.bottom-nav__flex-column a:hover {
  text-underline-offset: 0.3em;
  text-decoration: underline;
}

.bottom-nav__phone-button p, .bottom-nav__mail-button p {
    padding-left: 1rem;
    margin: 0;
}

.bottom-nav__mail-button {
    margin-left: 1rem;
}

.bottom-nav__links {
    width: 60%;
}

.bottom-nav__links__header{
    color: white !important;
    @include font-stack(gamma);
}

#bottom-nav__left {
    width: 40%;
}

#bottom-nav__left>p {
    width: 45%;
    color: white !important;
    margin: 1rem 0 2rem 0;
    @include font-stack(base);
}

@media only screen and (max-width: 1024px) {
    #bottom-nav__left>p {
        width: 70%;
    }

    #bottom-nav__left > .flex-row-plain {
        flex-direction: column;
    }

    .bottom-nav__phone-button {
        margin-bottom: 1rem;
    }

    .bottom-nav__mail-button {
        margin-left: 0;
    }

    .bottom-nav__flex-row {
        justify-content: space-between;
        width: 100%;
        flex-wrap: wrap;
    }

    .bottom-nav__flex-row > * {
      margin-right: 8px; 
    }
}

@media only screen and (max-width: 650px) {
    .bottom-nav__wrapper {
        width: 100%;
        flex-direction: column;
    }

    .bottom-nav__flex-row {
        justify-content: space-between;
        width: 100%;
    }

    .bottom-nav__links {
        width: 100%;
    }

    #bottom-nav__left {
        width: 100%;
        margin-bottom: 2rem;
    }

    #bottom-nav__left > p {
        width: 100%;
        max-width: 20rem;
        margin-top: 0;
    }

    #bottom-nav__left > .flex-row-plain {
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
    }

    .bottom-nav__phone-button {
        margin-bottom: 1rem;
        margin-right: 1rem;
    }

    .bottom-nav__mail-button {
      margin-bottom: 1rem;
    }
}

@media only screen and (max-width: 600px) {
    .bottom-nav__wrapper {
        padding: 2rem $sidesMobile;
    }

    .bottom-nav__flex-row {
        flex-wrap: wrap;
        //justify-content: center;
    }
}

@media only screen and (max-width: 360px) {
  .bottom-nav__button__icon {
      display: none;
  }

  .bottom-nav__phone-button p, .bottom-nav__mail-button p {
      padding-left: 0rem;
  }
}





