.consent-banner {
	visibility: hidden;

	position: fixed;
	right: 0;
	bottom: 0;
	z-index: 1000;
	max-width: 30rem;
	margin: 1rem;
  padding: 2rem ;
  border-radius: 5px;
  box-shadow: $strongShadow;
	overflow: hidden;
	display: flex;
	justify-content: center;
  flex-direction: column;

  background-color: white;
}

.consent-banner__headline {
  color: $blue-500;
	hyphens: auto;
}

.consent-banner__accept {
    @include buttonBlue;
    color: white;
    margin-right: 1.5rem;
    border: none;
}

.consent-banner__decline {
  padding: 0;
	border: none;
	text-decoration: none;
	color: inherit;
	background: none;
	cursor: pointer;

  text-decoration: underline;
	text-underline-offset: 0.333em;
}

.dsg-settings {
	cursor: pointer;
}

@media only screen and (max-width: 600px) {
	.consent-banner {
		top: 5rem;
		bottom: unset;
		right: unset;
		left: 0;
		max-width: calc(100% - 2rem);
		padding: 1rem;
	}
}




