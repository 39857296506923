@mixin buttonRound {
    width: 2rem;
    height: 2rem; 
    
    background-clip: padding-box;
    border-radius: 100%;
    border: 1rem solid rgba(252, 195, 0, 0.2);
    background-color: $yellow;
    padding: 0.75rem;

    transition: all ease .6s;

    cursor: pointer;

    &:hover {
        border: 1rem solid rgba(252, 195, 0, 0.6);
        background-color: white;
    }

    &:after {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        top: 50%;
        left: 50%;
        transform-style: flat;
        transform: translate3d(-50%,-50%,0);
        background: rgba(white,.2);
        border-radius: 100%;
        transition: width .3s ease, height .3s ease;
      }
    &:active {
        &:after {
            width: 100px;
            height: 100px;
        }
    }
}

