.text-block {
    padding: 2rem 0 2rem $sidesMobile;
    position: relative;
    width: 100vw;

    max-width: $maxWidth;
    margin-left: auto;
    margin-right: auto;
}

.text-block__text {
    width: 50%;
    @include font-stack(base);
}

@media only screen and (max-width: 600px) {
    .text-block {
        padding: 2rem $sidesMobile;
        flex-direction: column;
    }

    .text-block__text {
        width: 100%;
        order: 1;
    }
}
