input[type="text"],
input[type="email"],
input[type="number"],
input[type="tel"],
textarea {
    border-radius: 4px;
    background-color: $blue-50;
    @include font-stack(base);
    border: 0px;
    padding: 1rem;

    &:focus, &:active {
        outline-color: $blue-200;
    }
}

textarea {
    resize: none;
    justify-content: start;
}

.form-label__optional {
    color: $grey;
}

.input--error, .textarea--error {
    background-color: $red-50 !important;
    &:focus, &:active {
        outline-color: $red-200 !important;
    }
}

.error-icon {
    position: absolute;
    @include svg__filter__red200;
    top: 3rem;
    right:1rem;
    width: 1rem;
    height: 1rem;
}

.input-icon {
    position: absolute;
    @include svg__filter__blue200;
    top: 3rem;
    right:1rem;
    width: 1rem;
    height: 1rem;
}

input {
    position: relative;
    margin-bottom: 1rem;
    width: 100%;
}

label {
    @include font-stack(delta);
    margin-bottom: 0.25rem;
    margin-left: 0.25rem;
}

/**
 * Hide number input controls for multiple browsers
 */
 input::-webkit-outer-spin-button,
 input::-webkit-inner-spin-button {
     -webkit-appearance: none;
     margin: 0;
 }

 input[type=number] {
     -moz-appearance:textfield; /* Firefox */
 }

 .input--error {
    padding-right: 2.5rem !important;
}

/**
 * Prevent certain browsers from colouring the inputs when autofilling
 */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
}

.select {
    position: relativ;


}

.select__input {
  appearance: none;
	-webkit-appearance: none;
	&::-ms-expand {
		display: none;
	}

	> option:disabled {
		display: none;
	}

    width: 100%;
    border-radius: 4px;
    background-color: $blue-50;
    @include font-stack(base);
    border: 0px;
    padding: 1rem;

    &:focus, &:active {
        outline-color: $blue-200;
    }
}

.select__icon {
  @include svg__filter__blue300;
  height: auto;
  width: 1.5rem;
  position: absolute;
  bottom: 0.9rem;
  right: 1rem;
}

.select__hidden {
  display: none;
}
