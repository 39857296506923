.card-grid {
    background-color: $blue-50;
    width: 100%;
}

.card-grid__wrapper {
  padding: 4rem $sidesMobile;
  max-width: $maxWidth;
  margin-left: auto;
  margin-right: auto;
}

.card-grid__header {
    @include font-stack(beta);
    margin-bottom: 2rem;
}

.card-grid__grid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    gap: 1rem;
    margin: 0;
}

.card-grid__card {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;

    width: 23%;
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.05), 0 1px 2px 0 rgba(0,3,5,0.20);
    border-radius: 3px;
    padding: 1.5rem;

    &::after {
        content: '';
        position: absolute;
        background-color: $yellow;
        z-index: 5;
        top: 0;
        left: 1.5rem;
        width: 4rem;
        height: 0.25rem;
        border-radius: 0;
    }
}

.card-grid__card__headline {
    @include font-stack(gamma);
    margin: 0;
    margin-bottom: 0.5rem;
    hyphens: auto;
}


.card-grid__card__number {
    margin: 0;
    background-color: $blue-100;
    border-radius: 1rem;
    width: fit-content;
    padding: 0.25rem 1.5rem;
    text-align: center;
}

@media only screen and (max-width: 1490px) {
    .card-grid__card {
        min-width: 15rem;
    }
}

@media only screen and (max-width: 655px) {
    .card-grid__card {
        min-width: 100%;
    }
}

@media only screen and (max-width: 600px) {
    .card-grid {
        padding: 0;
        min-width: unset;
        width: 100%;
    }
}
