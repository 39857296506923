.link-block {
  position: absolute;
  top: 48rem;
  right: 0;

  z-index: 666;
  margin: 0 $sidesMobile;

  border-radius: 3px;
  background-color: $yellow;
  height: 16rem;
  width: 16rem;
  overflow: hidden;
  padding: 2rem;

  box-shadow: 0 1px 2px 0 rgba(1,3,4,.1),0 5px 10px 0 rgba(1,3,4,.05);
}

.link-block__inside {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  gap: 1rem;
  height: 100%;
}

.link-block__text {
  position: relative;
}

.link-block__icon {
  height: 1rem;
  width: 1rem;
  position: absolute;
  bottom: 0.25rem;
  right: 0;
}

.link-block__image {
  width: 80%;
  height: auto;
}

.link-block__text {
  width: 90%;
  margin: 0;
  color: black !important;
  @include font-stack(gamma);
}


@media only screen and (max-width: 1024px) {
  .link-block {
    top: 41.5rem;
  }
}

@media only screen and (max-width: 768px) {
  .link-block {
    position: fixed;
    bottom: 2rem;
    height: fit-content;
    margin: $sidesMobile;
    top: unset;
  }

  .link-block__text {
    width: 100%;
  }

  .link-block__image {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  .link-block {
      position: unset;
      z-index: 1;
      left: unset;
      top: unset;
      padding: 2rem 2rem 1rem 2rem;
      width: 80%;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 2rem;
  }

  .link-block__inside {
      gap: 2rem;
  }

  .link-block__image {
    display: block;
  }
}
