.illustration__button {
    @include buttonRound;
    position: absolute;
}

#elektrotechnik__link {
    width: auto;
    height: auto;
    position: absolute;
    top: 73%;
    left: 36%;
}

#leitungsbau__link {
    position: absolute;
    top: 76%;
    left: 74%;
}

#breitband__link {
    position: absolute;
    top: 63%;
    left: 61.5%;
}

#fernmeldetechnik__link {
    position: absolute;
    top: 67%;
    left: 54.5%;
}

#endstellenbau__link {
    position: absolute;
    top: 65%;
    left: 91%;
}

@media only screen and (max-width: 1200px) {
  #elektrotechnik__link {
      top: 78%;
      left: 35%;
  }

  #leitungsbau__link {
      top: 79%;
      left: 73%;
  }

  #breitband__link {
      top: 69%;
      left: 61%;
  }

  #fernmeldetechnik__link {
      top: 72%;
      left: 54%;
  }

  #endstellenbau__link {
      top: 69%;
      left: 90.5%;
  }
}

@media only screen and (max-width: 900px) {
  #elektrotechnik__link {
      top: 82%;
      left: 35%;
  }

  #leitungsbau__link {
      top: 83%;
      left: 73%;
  }

  #breitband__link {
      top: 74%;
      left: 61%;
  }

  #fernmeldetechnik__link {
      top: 76%;
      left: 54%;
  }

  #endstellenbau__link {
      top: 7%;
      left: 90.5%;
  }
}

@media only screen and (max-width: 768px) {
    #elektrotechnik__link {
        top: 82%;
        left: 19%;
    }

    #leitungsbau__link {
        top: 84%;
        left: 65%;
    }

    #breitband__link {
        top: 75%;
        left: 51%;
    }

    #fernmeldetechnik__link {
        top: 78%;
        left: 42%;
    }

    #endstellenbau__link {
        top: 76%;
        left: 89%;
    }
}

@media only screen and (max-width: 600px) {
    #elektrotechnik__link {
        top: 83%;
        left: 17%;
    }

    #leitungsbau__link {
        top: 84%;
        left: 63%;
    }

    #breitband__link {
        top: 76%;
        left: 50%;
    }

    #fernmeldetechnik__link {
        top: 73%;
        left: 35%;
    }

    #endstellenbau__link {
        top: 75%;
        left: 84%;
    }
}

@media only screen and (max-width: 450px) {
  #elektrotechnik__link {
      top: 87%;
      left: 17%;
  }

  #leitungsbau__link {
      top: 88%;
      left: 63%;
  }

  #breitband__link {
      top: 81%;
      left: 48.5%;
  }

  #fernmeldetechnik__link {
      top: 77%;
      left: 33%;
  }

  #endstellenbau__link {
      top: 80%;
      left: 86%;
  }
}

@media only screen and (max-width: 350px) {
  #elektrotechnik__link {
      top:90%;
      left: 17%;
  }

  #leitungsbau__link {
      top: 92%;
      left: 63%;
  }

  #breitband__link {
      top: 85%;
      left: 48.5%;
  }

  #fernmeldetechnik__link {
      top: 83%;
      left: 31%;
  }

  #endstellenbau__link {
      top: 85%;
      left: 83%;
  }
}
