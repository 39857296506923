@mixin controlButton {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    
    padding: 0.5rem 1.5rem;
    border-radius: 2rem;
    background-color: $yellow-500;
    //box-shadow: 0 1px 2px 0 rgba(1,3,4,0.15), 0 10px 15px 0 rgba(1,3,4,0.05), inset 0 1px 0 0 #F5F9FC;

    &:hover, :focus {
        background: darken($yellow-500,7%);
    }
}